import React from "react"
import { Link } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import workerSend from "../appWorker"
import { leaveGameLocal } from "../actions"

function Footer() {
  const dispatch = useDispatch()
  const user = useSelector(state => state.user)

  const leaveGame = () => {
    workerSend("leaveGame")
    dispatch(leaveGameLocal())
  }
  return (
    <>
      {user.teamId >= 0 && (
        <footer className="p-4 text-center">
          <Link to="/JoinOrCreateGame" onClick={() => leaveGame()} className="bg-red-600 text-white visited:text-white rounded px-2 py-2 leading-3 transition-all text-xxs uppercase tracking-widest">Leave Game</Link>
        </footer>
      )}
    </>
  )
}

export default Footer
