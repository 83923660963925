import React from "react"
import { NavLink } from "react-router-dom"
import { useSelector } from "react-redux"

export const contractAlert = (squadOrder, players) => {
	let needAttention = 0
	squadOrder.forEach(playerId => {
		if (players[playerId].contractExpiry < 1)
			needAttention++
	})
	return needAttention
}

const bidAlert = (bids, TeamId) => {
	let totalBids = 0
	bids.forEach(bid => {
		if (bid.sellingTeamId === TeamId && bid.status === "") {
			totalBids++
		}
	})
	return totalBids
}

function SubNav(props) {
	const type = props.type
	const user = useSelector(state => state.user)
	const teams = useSelector(state => state.teams)
	const players = useSelector(state => state.players)
	const market = useSelector(state => state.market)

	const totalBids = bidAlert(market.bids, user.teamId)
	const playerContractsNeedingAttention = user.teamId > -1 ? contractAlert(teams[user.teamId].squadOrder, players) : 0

	if (user.teamId < 0) return null
	switch (type) {
		case 'money':
			return (
				<ul className="flex place-content-evenly py-4 bg-[#c50]">
					<li className="text-xxs uppercase tracking-widest"><NavLink className="text-white" to="/finances">Finances</NavLink></li>
					<li className="text-xxs uppercase tracking-widest"><NavLink className="text-white" to="/player-contracts">
						Player Contracts {playerContractsNeedingAttention > 0 && (
							<span className="notification--bubble text-center radius-50 color-white inline-block font-mono foont-normal p-1 rounded-full leading-4 text-white absolute -top-[2ch] -right-[2ch] bg-orange">
								{playerContractsNeedingAttention}
							</span>
						)}
					</NavLink></li>
				</ul>
			)
		case 'matches':
			return (
				<ul className="flex place-content-evenly py-4 bg-[#c50]">
					<li className="text-xxs uppercase tracking-widest"><NavLink className="text-white" to="/fixtures">Fixtures</NavLink></li>
					<li className="text-xxs uppercase tracking-widest"><NavLink className="text-white" to="/results">Results</NavLink></li>
					<li className="text-xxs uppercase tracking-widest"><NavLink className="text-white" to="/league">League</NavLink></li>
					<li className="text-xxs uppercase tracking-widest"><NavLink className="text-white" to="/cups">Cups</NavLink></li>
				</ul>
			)
		case 'transfers':
			return (
				<ul className="flex place-content-evenly py-4 bg-[#c50]">
					<li className="text-xxs uppercase tracking-widest"><NavLink className="text-white" to="/bids">
						Bids{" "}
						{totalBids > 0 && (
							<span className="notification--bubble text-center radius-50 color-white inline-block font-mono foont-normal p-1 rounded-full leading-4 text-white absolute -top-[2ch] -right-[2ch] bg-orange">
								{totalBids}
							</span>
						)}
					</NavLink></li>
					<li className="text-xxs uppercase tracking-widest"><NavLink className="text-white" to="/transfer-list">Transfer List</NavLink></li>
					<li className="text-xxs uppercase tracking-widest"><NavLink className="text-white" to="/long-list">Long List</NavLink></li>
					<li className="text-xxs uppercase tracking-widest"><NavLink className="text-white" to="/free-agents">Free Agents</NavLink></li>
					<li className="text-xxs uppercase tracking-widest"><NavLink className="text-white" to="/transfers">Transfers</NavLink></li>
				</ul>
			)
		default: return null
	}
}

export default SubNav
