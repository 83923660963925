import seasonsData from "../data/seasons"
import resetTeamPoints from "../functions/resetTeamPoints"
import updateLeagueTeams from "../functions/updateLeagueTeams"
import { findTeamPos } from "../functions/seasons"

const seasonsReducer = (state = seasonsData, action) => {
  const newData = action.seasons

  switch(action.type) {
    case 'newGame':
      return seasonsData
    case 'gameJoined':
      return action.seasons
    case 'incrementalServerData':
      return (newData !== undefined) ? newData : state
    case 'fullServerData':
      return (newData !== undefined) ? newData : []
    case 'matchPlayed' :
      const result = action.result
      if (result.fixture.leagueId !== undefined) {
        const seasons = [...state]
        const league = [...seasons[result.fixture.leagueId]]
        const seasonLeague = {...league[0]}
        const seasonTeams = [...seasonLeague.teams]
        seasonLeague.teams = seasonTeams

        const homeTeamPos = findTeamPos(seasonTeams, result.fixture.homeId)
        const awayTeamPos = findTeamPos(seasonTeams, result.fixture.awayId)

        seasonTeams[homeTeamPos] = {...seasonTeams[homeTeamPos]}
        seasonTeams[awayTeamPos] = {...seasonTeams[awayTeamPos]}
        updateLeagueTeams(seasonTeams[homeTeamPos], seasonTeams[awayTeamPos], result.homeScore, result.awayScore)

        league[0] = seasonLeague
        seasons[result.fixture.leagueId] = league
        return seasons
      }
      return state

    case 'nextSeasons':
      let seasons = [...state]
      seasons.forEach((seasonLeague, leagueId) => {
        let league = [...seasonLeague]
        seasons[leagueId] = league
        league.unshift({
          year: league[0].year+1,
          teams: [...action.teams[leagueId]]
        })
        resetTeamPoints(league[0].teams)
      })
      return seasons

    default:
      return state;
  }
}

export default seasonsReducer;
