import React from 'react'
import { Link } from "react-router-dom"
import { useSelector } from "react-redux"
import { getTeamLeagueId } from "../functions/teams"
import { getFixturesForTeam } from "../functions/fixtures"
import moment from "moment"

import getCssClassName from "../functions/getCssClassName"

function Fixtures() {
  const user = useSelector(state => state.user)
  const teams = useSelector(state => state.teams)
  const team = teams[user.teamId]
  const seasons = useSelector(state => state.seasons)
  const fixtures = useSelector(state => state.fixtures)
  const leagues = useSelector(state => state.leagues)
  const cups = useSelector(state => state.cups)
  // const allFixtures = flattenFixtures(fixtures)
  const teamFixtures = getFixturesForTeam(fixtures.leagues[getTeamLeagueId(seasons, team.id)], fixtures.cups, team.id)
  // const [isFiltered, setFiltered] = useState(true)
  // const showFixtures = isFiltered ? teamFixtures : allFixtures

  const filterHeader = (
    <header>
      <h1 className="mb-4 text-2xl font-bold font-sans tracking-widest text-center">{team.name} Fixtures</h1>
    </header>
  )

  return (
    <div className="p-1 s:p-2 m:p-3 l:p-4">
      <div className="match-header">
        {filterHeader}
        <div className="card">
          <table className="table--fixtures">
            <tbody>
              {
                teamFixtures.map((fixture, i) => {
                  const competition = fixture.leagueId !== undefined
                    ? leagues[fixture.leagueId]
                    : cups[fixture.cupId]
                  // console.log(fixture)
                  // console.log(homeSide)
                  return fixture.homeId === team.id ? (
                    <tr key={i}>
                      <td>{moment(fixture.kickOff).format("MMM D, YYYY")}</td>
                      <td>H</td>
                      <td className={getCssClassName(`${teams[fixture.awayId].shortName}-${teams[fixture.awayId].logo}`)}>
                        <Link to={`/team/${getCssClassName(teams[fixture.awayId].shortName)}`}>{fixture.awayName}</Link>
                      </td>
                      <td>
                        <p className="fixture-row__date">{competition.name}</p>
                      </td>
                    </tr>
                  )
                    : (
                      <tr key={i}>
                        <td>{moment(fixture.kickOff).format("MMM D, YYYY")}</td>
                        <td>A</td>
                        <td className={getCssClassName(`${teams[fixture.homeId].shortName}-${teams[fixture.homeId].logo}`)}>
                          <Link to={`/team/${getCssClassName(teams[fixture.homeId].shortName)}`}>{fixture.homeName}</Link>
                        </td>
                        <td>
                          <p className="fixture-row__date">{competition.name}</p>
                        </td>
                      </tr>
                    )
                }
                )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default Fixtures
