const abbreviatePosition = position => {
    switch (position){
      case "Goalkeeper" : return "GK"
      case "Defender" : return "DF"
      case "Midfield" : return "MF"
      case "Forward" : return "FW"
      default : return null
    }
  }

  export default abbreviatePosition
