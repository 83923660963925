import { getPlayerRating } from "../../functions/players"
import randomNo from "../../functions/randomNo"
import matchFlows from "./matchFlows"

export const goalChance = (individualPerformance, teamPerformanceOffsetAttacking, goalkeeperPerformance) => {
  const performanceThisMoment = (individualPerformance / 2 + 0.3) + // e.g. 1.05
    (teamPerformanceOffsetAttacking / 200) + // e.g. with 200 a gap of 20 becomes a +.10 addition
    (Math.random()/3) // Performance consistency luck, e.g. +.20
  const goalkeeperThisMoment = goalkeeperPerformance / 10 // e.g. 1 / 10 takes away 0.1 chance
  const luckThisMoment = Math.random() - 0.3 // Opportunity luck - change for more / fewer goals overall per shot

  return Math.max(0, Math.round((performanceThisMoment - goalkeeperThisMoment) * luckThisMoment))
}
// Calculate a player's performance based on their rating, form, and each team's morale
// Could store this against a temporary player object, but unless we're putting in state it's easier to just call it twice
export const calcIndividualPerformance = (player, position, formation, teamMorale, opposingTeamMorale) => {
  //The number modifies how much rating (e.g. 50) affects performance
  const rating = (getPlayerRating(player)/45) + 1.8 // last number keeps overall function output in balance
  const positionOffset = getPlayerPositionOffset(player, position, formation)
  // The number determines how impactful form + condition + morale are on the result
  const formMoraleCondition = (((player.form+player.condition)/2 + teamMorale - opposingTeamMorale) / 320)

  return (rating * positionOffset + (0.85 + formMoraleCondition)) / 4
}

// Goal modifier based on pitch position (pos in array of 11 players for now)
export const playerShotChance = (individualPerformance, i, teamPerformanceOffsetAttacking) => {
  // e.g. a positive gap of 20 becomes a 1.2 modifier and vice-versa
  const teamChanceCreation = 1 + (teamPerformanceOffsetAttacking / 100)
  const playerChanceCreation = individualPerformance * 1.5
  const positionOffset = (i >= 8) ? 0.4 : (i >= 5) ? 0.15 : (i >= 1) ? 0.06 : 0.005

  return teamChanceCreation * playerChanceCreation * positionOffset - 0.1
}

const attackingModifier = (team, teamFlow) => {
  let modifier = 1
  switch (team.formation) {
    case '4-5-1': modifier -= 0.1; break
    case '3-4-3': modifier += 0.2; break
    case '3-5-2': modifier += 0.1; break
    default: return modifier
  }
  switch (team.tactic) {
    case 'Defensive': modifier -= 0.3; break
    case 'Possession': modifier -= 0.1; break
    case 'Attacking': modifier += 0.3; break
    case 'Counter-Attacking': modifier += 0.2; break
    default: return modifier
  }
  return modifier += teamFlow.attackingModifier
}

const defendingModifier = (team, teamFlow) => {
  let modifier = 1
  switch (team.formation) {
    case '4-5-1': modifier += 0.1; break
    case '3-4-3': modifier -= 0.3; break
    case '3-5-2': modifier -= 0.2; break
    default: return modifier
  }
  switch (team.tactic) {
    case 'Defensive': modifier += 0.3; break
    case 'Possession': modifier += 0.1; break
    case 'Attacking': modifier -= 0.3; break
    case 'Counter-Attacking': modifier += 0.1; break
    default: return modifier
  }
  return modifier += teamFlow.defendingModifier
}

export const getPlayerPositionOffset = (player, position, formation) => {
  const defence = parseInt(formation[0])
  const midfield = parseInt(formation[0])+parseInt(formation[2])

  switch (player.position) {
    case "Goalkeeper":
      return (position > midfield) ? 0.6 : (position > defence) ? 0.7 : (position > 0) ? 0.8 : 1
    case "Defender":
      return (position > midfield) ? 0.8 : (position > defence) ? 0.9 : (position > 0) ? 1 : 0.6
    case "Midfield":
      return (position > midfield) ? 0.9 : (position > defence) ? 1 : (position > 0) ? 0.9 : 0.5
    case "Forward":
      return (position > midfield) ? 1 : (position > defence) ? 0.8 : (position > 0) ? 0.7 : 0.4
    default:
      return 1
  }
}

const calcTeamPeriodGoals = (scorers, players, formation, teamMorale, opposingTeamMorale, teamPerformanceOffsetAttacking, opposingTeamGoalkeeperPerformance) => {
  let score = 0

  players.forEach((p, i) => {
    const individualPerformance = calcIndividualPerformance(p, i, formation, teamMorale, opposingTeamMorale)
    if (playerShotChance(individualPerformance, i, teamPerformanceOffsetAttacking) < Math.random()) {
      return
    }
    // Max 2 goals per player per sixth of the game
    // last number divided the gap between the teams, so e.g. with 80 a gap of 20 becomes a +.25 modifier
    let goals = Math.min(2, goalChance(
      calcIndividualPerformance(p, i, formation, teamMorale, opposingTeamMorale),
      teamPerformanceOffsetAttacking,
      opposingTeamGoalkeeperPerformance
    ))
    if (goals > 0) {
      if (scorers.findIndex(s => s.playerId === p.id) > -1) {
        scorers.find(s => s.playerId === p.id).goals += goals
      } else {
        scorers.push({ playerId: p.id, goals: goals })
      }
      score += goals
    }
  })
  return score
}

const calcSubsetPerformance = (players, positions, formation, morale, opposingMorale, teamPerformance) => {
  return (
    (
      players
        .map((p, i) => calcIndividualPerformance(p, positions[i], formation, morale, opposingMorale))
        .reduce((a, b) => a + b, 0)
       / players.length
     )
     * 3 + teamPerformance // subset performance 4 times more relevant than whole team performance
   ) / 4
}

export const playMatch = (home, homePlayers, away, awayPlayers) => {
  const homeSalary = getSalary(homePlayers)
  const awaySalary = getSalary(awayPlayers)
  const homeEleven = homePlayers.slice(0, 11)
  const homeMorale = homeEleven.map(p => p.form).reduce((a, b) => a + b, 0) / 11 // TODO maybe use morale instead of form?
  const awayEleven = awayPlayers.slice(0, 11)
  const awayMorale = awayEleven.map(p => p.form).reduce((a, b) => a + b, 0) / 11 // TODO maybe use morale instead of form?

  const homePerformance = homeEleven.map((p,i) => calcIndividualPerformance(p, i, home.formation, homeMorale, awayMorale)).reduce((a, b) => a + b, 0) / 11
  const awayPerformance = awayEleven.map((p,i) => calcIndividualPerformance(p, i, away.formation, awayMorale, homeMorale)).reduce((a, b) => a + b, 0) / 11
  const homeDefencePerformance = calcSubsetPerformance(homeEleven.filter((p,i) => i > 0 && i < 5), [1,2,3,4], home.formation, homeMorale, awayMorale, homePerformance)
  const awayDefencePerformance = calcSubsetPerformance(awayEleven.filter((p,i) => i > 0 && i < 5), [1,2,3,4], away.formation, awayMorale, homeMorale, awayPerformance)
  const homeAttackPerformance = calcSubsetPerformance(homeEleven.filter((p,i) => i > 5), [6,7,8,9,10], home.formation, homeMorale, awayMorale, homePerformance)
  const awayAttackPerformance = calcSubsetPerformance(awayEleven.filter((p,i) => i > 5), [6,7,8,9,10], away.formation, awayMorale, homeMorale, awayPerformance)
  const homeGoalkeeperPerformance = calcIndividualPerformance(homeEleven[0], 0, home.formation, homeMorale, awayMorale)
  const awayGoalkeeperPerformance = calcIndividualPerformance(awayEleven[0], 0, away.formation, awayMorale, homeMorale)

  const matchPeriods = Array(6).fill(null)

  let homeScore = 0, awayScore = 0
  let homeScorers = [], awayScorers = []

  matchPeriods.forEach((matchPeriod, i) => {
    let matchFlow = matchFlows[randomNo(matchFlows.length-1)]
    let j = randomNo(1)

    const homeTeamPerformanceOffsetAttacking =
      (homeAttackPerformance * attackingModifier(home, matchFlow[j]))
      - (awayDefencePerformance * defendingModifier(away, matchFlow[j?0:1]))
    const awayTeamPerformanceOffsetAttacking =
      (awayAttackPerformance * attackingModifier(away, matchFlow[j?0:1]))
      - (homeDefencePerformance * defendingModifier(home, matchFlow[j]))

    homeScore += calcTeamPeriodGoals(homeScorers, homeEleven, home.formation, homeMorale, awayMorale, homeTeamPerformanceOffsetAttacking, awayGoalkeeperPerformance)
    awayScore += calcTeamPeriodGoals(awayScorers, awayEleven, away.formation, awayMorale, homeMorale, awayTeamPerformanceOffsetAttacking, homeGoalkeeperPerformance)
  });

  return [homeScore, awayScore, homeScorers, awayScorers, homeSalary, awaySalary]
}

const getSalary = (squad) => squad.reduce((a, b) => {
  return a + b.wage
}, 0)
