const updateLeagueTeams = (home, away, homeScore, awayScore) => {
  if (homeScore > awayScore) {
    home.points += 3
    away.points += 0
    home.won ++
    away.lost ++
  } else if (homeScore < awayScore) {
    home.points += 0
    away.points += 3
    home.lost ++
    away.won ++
  } else {
    home.points += 1
    away.points += 1
    home.drawn ++
    away.drawn ++
  }
  home.played++
  away.played++
  home.for += homeScore
  away.for += awayScore
  home.against += awayScore
  away.against += homeScore
}

export default updateLeagueTeams
