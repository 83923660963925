import React, { useState } from "react"
import { useSelector } from "react-redux"
import abbreviatePosition from "../functions/abbreviatePosition"
import Modal from "react-modal"
import PlayerDetail from "./PlayerDetail"
import BidForPlayer from "./BidForPlayer"

import getCssClassName from "../functions/getCssClassName"
import { Link } from "react-router-dom"
import marketValue from "../functions/marketValue"
import Stars from "./Stars"

function LongList() {
  const user = useSelector(state => state.user)
  let players = useSelector(state => state.players)
  const teams = useSelector(state => state.teams)
  const team = teams[user.teamId]

  const [player, setPlayer] = useState(false)

  return (
    <div className="p-1 s:p-2 m:p-3 l:p-4">
      {player &&
        <Modal ariaHideApp={false} isOpen={player.id >= 0}>
          {<PlayerDetail player={player} />}
          {<BidForPlayer player={player} setPlayer={setPlayer} />}
          <button className="f-r" onClick={() => setPlayer(false)}>Close</button>
        </Modal>
      }
      <header className="center">
        <h1 className="mb-4 text-2xl font-bold font-sans tracking-widest text-center">Long List</h1>
        <h3>Bank Balance: <span className="monospace">£{team.bankBalance.toLocaleString()}</span></h3>
      </header>
      <table className="table--ghost card">
        <thead><tr><th></th><th>POS</th><th>Name</th><th>Age</th><th>Rating</th><th>Club</th><th>Wage</th><th>Value</th></tr></thead>
        <tbody>
          {user.longList.length === 0 && <tr><td className="c" colSpan="8">No players in long list</td></tr>}
          {
            user.longList.map((playerId, i) => {
              let p = players[playerId]
              return (<tr key={i}>
                <td className="c">{p.loanListed && <i className="icon--loan-listed"></i>}{p.transferListed && <i className="icon--transfer-listed"></i>}</td>
                <td>{abbreviatePosition(p.position)}</td>
                <td className="onClick" onClick={() => setPlayer(p)}>{`${p.firstName} ${p.lastName}`}</td>
                <td>{p.age}</td>
                <td><Stars player={p} /></td>
                {
                  teams[p.teamId]
                    ? <td className={getCssClassName(teams[p.teamId].shortName)}><Link to={`/team/${teams[p.teamId].shortName}`}>{teams[p.teamId].shortName}</Link></td>
                    : <td>Free agent</td>
                }
                <td className="accounting">{p.wage.toLocaleString()}</td>
                <td className="accounting">{marketValue(p, p.contractExpiry).toLocaleString()}</td>
              </tr>)
            }
            )
          }
        </tbody>
      </table>
    </div>
  );
}

export default LongList;
