const resetTeamPoints = (teams) => {
  for (let i = 0; i < teams.length; i++) {
    teams[i] = {...teams[i]}
    teams[i].played = 0
    teams[i].won = 0
    teams[i].drawn = 0
    teams[i].lost = 0
    teams[i].for = 0
    teams[i].against = 0
    teams[i].points = 0
  }
}

export default resetTeamPoints
