const initialState = new Array(40).fill([])
const milestonesReducer = (state = initialState, action) => {
    let milestones = [...state]
    const newData = action.milestones
    
    switch(action.type) {
      case 'newGame':
        return initialState
      case 'gameJoined':
        return action.milestones
      case 'incrementalServerData':
        return (newData !== undefined) ? newData : state
      case 'fullServerData':
        return (newData !== undefined) ? newData : []
      case "addMilestone" :
        const event = {
          leagueId : action.leagueId,
          cupId: action.cupId,
          milestoneType : action.milestone,
        }
        if (!milestones[action.teamId]) milestones[action.teamId] = []
        milestones[action.teamId] = [...milestones[action.teamId]]    // Spread em punk!
        milestones[action.teamId].unshift(event)
        return milestones
    default:
          return milestones;
    }
}
export default milestonesReducer;
