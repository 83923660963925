const addNews = (news, userId, date, teamId, body, link, concern) => {
  if (!(userId in news)) {
    news[userId] = []
  }
  news[userId].push(makeNews(date, teamId, body, link, concern))
}

const makeNews = (date, teamId, body, link, concern) => {
  return {
    date,
    teamId,
    body,
    link,
    concern,
    archived: false
  }
}

export {
  addNews,
  makeNews
}
