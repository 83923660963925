import React, { useState } from "react"
import { useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import Modal from "react-modal"

import abbreviatePosition from "../functions/abbreviatePosition"
import { getTeamIdByShortName } from "../functions/teams"
import PlayerDetail from "./PlayerDetail"
import BidForPlayer from "./BidForPlayer"
import Stars from "./Stars"

const calculateStature = stature => {
    if (stature <= 20) {
        return "Small"
    } else if (stature <= 40) {
        return "Average"
    } else if (stature <= 60) {
        return "Large"
    } else if (stature <= 80) {
        return "Large"
    } else if (stature > 81) {
        return "Huge"
    }
}

function Team() {
    const teams = useSelector(state => state.teams)
    const players = useSelector(state => state.players)
    const [player, setPlayer] = useState(false)
    let { teamName } = useParams()
    teamName = decodeURI(teamName)

    const teamId = getTeamIdByShortName(teams, teamName)

    return (
        <div className="p-1 s:p-2 m:p-3 l:p-4">
            <header className="center">
                <h1 className="mb-4 text-2xl font-bold font-sans tracking-widest text-center">{teams[teamId].name}</h1>
            </header>
            {
                player &&
                <Modal ariaHideApp={false} isOpen={player.id >= 0}>
                    {<PlayerDetail player={player} />}
                    {<BidForPlayer player={player} setPlayer={setPlayer} />}
                    <button onClick={() => setPlayer(false)}>Close</button>
                </Modal>
            }
            <ul class="bosh">
                <li><span>Stadium:</span><span>{teams[teamId].stadium.name} ({teams[teamId].stadium.capacity.toLocaleString()})</span></li>
                <li><span>Continent:</span><span>{teams[teamId].continent}</span></li>
                <li><span>Stature:</span><span>{calculateStature(teams[teamId].stature)}</span></li>
            </ul>
            <div className="card">
                <table className="table--lineup">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Name</th>
                            <th>Position</th>
                            <th>Rating</th>
                            <th><abbr title="Appearances">APP</abbr></th>
                            <th><abbr title="Goals">GLS</abbr></th>
                            <th>Form</th>
                        </tr>
                    </thead>
                    <tbody>
                        {teams[teamId].squadOrder.map((playerId, i) => {
                            let player = players[playerId]
                            return (
                                <tr key={player.id}>
                                    <td className="c">{player.loanListed && <i className="icon--loan-listed"></i>}{player.transferListed && <i className="icon--transfer-listed"></i>}</td>
                                    <td className="onClick" onClick={() => setPlayer(player)}>{`${player.firstName} ${player.lastName}`}</td>
                                    <td className={player.position.toLowerCase()}>{abbreviatePosition(player.position)}</td>
                                    <td><Stars player={player} /></td>
                                    <td>{player.apperearances}</td>
                                    <td>{player.goals}</td>
                                    <td>{player.form}</td>
                                </tr>
                            )
                        }
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Team
