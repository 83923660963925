import { createStore } from "redux";
import allReducers from "./reducers";
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import autoMergeLevel1  from 'redux-persist/lib/stateReconciler/autoMergeLevel1'

const persistConfig = {
  key: 'unbeatenrun',
  storage,
  stateReconciler: autoMergeLevel1,
}

const persistedReducer = persistReducer(persistConfig, allReducers)

const store = createStore(
  persistedReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store
