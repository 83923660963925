const getLeagueStartDate = (competitionFirstYear) => {
  const leagueStartDate = new Date("2021-08-15T12:00:00.000Z")
  leagueStartDate.setFullYear(competitionFirstYear)
  return getDateWithAddedDays(leagueStartDate, 6 - leagueStartDate.getDay())
}

const generateAllLeagueFixtures = (seasonTeams, leagues, seasons, teams) => {
  let fixtures = []
  leagues.forEach((league, i) => {
    fixtures[league.id] = generateLeagueFixtures(
      getLeagueStartDate(seasons[league.id][0].year-1),
      league.id,
      seasons[league.id][0].year,
      seasonTeams[league.id].map(team => teams[team.teamId])
    )
  })
  return fixtures
}

const generateFirstLeagueFixtures = (leagues, seasons, teams) => {
  let seasonTeams = []
  seasons.forEach((leagueSeasons, leagueId) => seasonTeams[leagueId] = leagueSeasons[0].teams)
  return generateAllLeagueFixtures(seasonTeams, leagues, seasons, teams)
}


const generateLeagueFixtures = (currentDate, leagueId, competitionYear, teams) => {
  let fixtureDate = currentDate;
  let fixtures = []
  // for as many games as each team should play (as many moving positions as there are to occupy), play the team opposite
  // For home
  for (let i = 0; i < (teams.length - 1) * 2; i++)
  {
    fixtures[i] = []
    // for each fixture that need to happen between opposite players
    for (let k = 0; k < (teams.length / 2); k++)
    {
      // Everyone play the person opposite them
      if (i % 2 > 0)
      {
        // First, odd iterations at home
        let awayTeamIndex = teams.length - 1 - k
        let homeTeamIndex = 0 + k
        let homeId = teams[homeTeamIndex].id
        let awayId = teams[awayTeamIndex].id
        let id = Math.round(fixtureDate.getTime()/1000) + "l" + leagueId + homeId + awayId
        fixtures[i].push({id, leagueId, competitionYear, homeId, homeName: teams[homeTeamIndex].name, awayId, awayName: teams[awayTeamIndex].name, kickOff: fixtureDate.toISOString()});
      }
      else
      {
        // Second, even iterations away
        let homeTeamIndex = teams.length - 1 - k
        let awayTeamIndex = 0 + k
        let homeId = teams[homeTeamIndex].id
        let awayId = teams[awayTeamIndex].id
        let id = Math.round(fixtureDate.getTime()/1000) + "l" + leagueId + homeId + awayId
        fixtures[i].push({id, leagueId, competitionYear, homeId, homeName: teams[homeTeamIndex].name, awayId, awayName: teams[awayTeamIndex].name, kickOff: fixtureDate.toISOString()});
      }
    }
    // Move the date on
    fixtureDate = getDateWithAddedDays(fixtureDate, 7);
    // Swap seats
    teams = shuffleTeams(teams);
  }

  return fixtures;
}

const getDateWithAddedDays = (fixtureDate, days) => {
  let newFixtureDate = new Date(fixtureDate);
  newFixtureDate.setDate(newFixtureDate.getDate() + days);
  return newFixtureDate
}

const shuffleTeams = (teams) => {
  // Shuffle the teams around in round robin, leaving the team in position zero as the hub
  let poppedTeam = teams[teams.length - 1];
  for (let i = teams.length - 1; i > 0; i--)
  {
      teams[i] = teams[i - 1];
  }
  teams[1] = poppedTeam;
  return teams
}

export {
  getLeagueStartDate,
  getDateWithAddedDays,
  generateAllLeagueFixtures,
  generateFirstLeagueFixtures
}
