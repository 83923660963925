const boards = [
  { confidence: 50 },
  { confidence: 50 },
  { confidence: 50 },
  { confidence: 50 },
  { confidence: 50 },
  { confidence: 50 },
  { confidence: 50 },
  { confidence: 50 },
  { confidence: 50 },
  { confidence: 50 },
  { confidence: 50 },
  { confidence: 50 },
  { confidence: 50 },
  { confidence: 50 },
  { confidence: 50 },
  { confidence: 50 },
  { confidence: 50 },
  { confidence: 50 },
  { confidence: 50 },
  { confidence: 50 },
  { confidence: 50 },
  { confidence: 50 },
  { confidence: 50 },
  { confidence: 50 },
  { confidence: 50 },
  { confidence: 50 },
  { confidence: 50 },
  { confidence: 50 },
  { confidence: 50 },
  { confidence: 50 },
  { confidence: 50 },
  { confidence: 50 },
  { confidence: 50 },
  { confidence: 50 },
  { confidence: 50 },
  { confidence: 50 },
  { confidence: 50 },
  { confidence: 50 },
  { confidence: 50 },
  { confidence: 50 },
];
export default boards;
