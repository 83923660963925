const getFixtureGroupPos = (fixtureGroups, fixture) => {
  return fixtureGroups.findIndex(fixtureGroup => getFixturePos(fixtureGroup, fixture) > -1)
}

const getFixturePos = (fixtureGroup, fixture) => {
  return fixtureGroup.findIndex((f) => {
    return fixture.homeId === f.homeId &&
      fixture.awayId === f.awayId &&
      fixture.kickOff === f.kickOff
  })
}

const flattenFixtures = (allFixtures, type) => {
  let fixtures = []
  if (!type || type === 'league') {
    allFixtures.leagues.forEach(leagueFixtures =>
      leagueFixtures.forEach(leagueFixtureGroups =>
        leagueFixtureGroups.forEach(fixtureGroup => fixtures.push(fixtureGroup))
      )
    )
  }

  if (!type || type === 'cup') {
    allFixtures.cups.forEach(cupFixtures => cupFixtures.forEach(cupFixture => fixtures.push(cupFixture)))
  }
  return fixtures.sort((a, b) => a.kickOff < b.kickOff ? -1 : 1)
}

const getFixturesForTeam = (leagueFixtureGroups, cupFixtures, thisTeamId) => {
  let fixtures = []
  let fixture

  const addTeamFixtures = (competitionFixtures) => {
    fixture = competitionFixtures.filter(fixture => fixture.homeId === thisTeamId || fixture.awayId === thisTeamId).pop()
    if (fixture) fixtures.push(fixture)
  }

  if (leagueFixtureGroups && leagueFixtureGroups.length > 0)
    leagueFixtureGroups.forEach(fixtureGroup => addTeamFixtures(fixtureGroup))

  if (cupFixtures && cupFixtures.length > 0)
    cupFixtures.forEach(fixtures => addTeamFixtures(fixtures))

  fixtures.sort((a, b) => a.kickOff < b.kickOff ? -1 : 1)
  return fixtures
}

const getNextFixtureForTeam = (leagueFixtureGroups, cupFixtures, thisTeamId) =>
  getFixturesForTeam(leagueFixtureGroups, cupFixtures, thisTeamId)[0]

const getNextCompetitionFixtureDetails = (fixtures, type) => {
  const allFixtures = flattenFixtures(fixtures, type)
  if (!allFixtures.length) return null
  const fixture = allFixtures[0]
  const nextFixtureCompetitionId = fixture.leagueId >= 0 ? fixture.leagueId : fixture.cupId
  const nextFixtureCompetitionType = fixture.leagueId >= 0 ? 'league' : 'cup'
  return { nextFixtureCompetitionId, nextFixtureCompetitionType, nextFixtureKickOff: fixture.kickOff }
}

export {
  getFixtureGroupPos,
  getFixturePos,
  flattenFixtures,
  getFixturesForTeam,
  getNextFixtureForTeam,
  getNextCompetitionFixtureDetails
}
