import { releasePlayer } from "../actions"
import { addNews } from "./addNews"
import sortLeagueTeams from "./sortLeagueTeams"
import positionNumberToString from "./positionNumberToString"
import { getTeamLeagueId } from "./teams"
import store from '../store'

const dispatch = (action) => store.dispatch(action)

const generateNews = (users, seasons, teams, currentTime, players) => {
  const news = []
  teams.filter(team => team.userId).forEach(team => {
    // Find the division the user's team was in this season
    const lastLeagueSeasonForUserTeam = seasons[getTeamLeagueId(seasons, team.id)][0]
    const sortedLastLeagueSeasonForUserTeam = sortLeagueTeams(lastLeagueSeasonForUserTeam.teams)  // 0 is the first, latest season
    const teamIndex = sortedLastLeagueSeasonForUserTeam.findIndex(seasonTeam => seasonTeam.teamId === team.id) // work out their league finish
    const { played, won } = sortedLastLeagueSeasonForUserTeam[teamIndex]  // How did they perform?
    const winPercentage = Math.floor((won / played) * 100)
    // TODO: Create some brackets for success (if champion/promoted/mid-table/narrowly avoided relegation/relegated)
    // TODO: Add in the board's confidence
    // Generate a string describing the team's season
    const user = users.find(user => user.id === team.userId)
    let bodyString = `${team.name} finished in ${positionNumberToString(teamIndex + 1)} position. `
    bodyString += `Under ${user.managerName}, ${team.shortName} managed to win ${winPercentage}% of their games this year`

    addNews(news, team.userId, currentTime, team.id, bodyString, "league", "milestone")

    // Release the players whose contract just expired
    players
    .filter(player => player.teamId === team.id)
    .forEach(player => {
      if ( player.contractExpiry < 1 ){
        dispatch(releasePlayer(player, team.id, 0)) // player, teamId, fee
        addNews(news, team.userId, currentTime, team.id, `😭 ${player.firstName} ${player.lastName}'s contract has expired; they have today left the club`, "player-contracts", "players")
      }
    })
  });
  return news
}

const generateLeaguePrizeMoney = (leagues, seasons) => {
  const newMonies = []
  // Prize money
  leagues.forEach((league, leagueId) => {
    let winnings = league.topPrizeMoney
    const sortedSeasonTeams = sortLeagueTeams(seasons[leagueId][0].teams)

    sortedSeasonTeams.forEach((seasonTeam, finishPosition) => {
      newMonies[seasonTeam.teamId] = winnings
      winnings -= league.topPrizeMoney / 20
    })
  });
  return newMonies
}

const generateCupYears = (leagues, seasons, cups, nextCupYear) => {
  let teamCupYears = []
  leagues.forEach((league, leagueId) => {
    const sortedSeasonTeams = sortLeagueTeams(seasons[leagueId][0].teams)
    sortedSeasonTeams.forEach((seasonTeam, finishPosition) => {
      addTeamToCups(teamCupYears, cups, seasonTeam.teamId, finishPosition, league, nextCupYear);
    })
  })
  return teamCupYears
}

const addTeamToCups = (teamCups, cups, teamId, zeroBasedTeamPositionInLeague, league, year) => {
  cups.forEach((cup, i) => {
    if (zeroBasedTeamPositionInLeague < cup.topXFromLeague &&
       cup.continents.includes(league.continent) &&
       cup.tiers.includes(league.tier) &&
       cup.nextYear === year) {
         teamCups.push({cupId: cup.id, teamId, year})
    }
  })
}


const teamsToMoveEachWay = 4
const generateSeasonTeams = (leagues, seasons, teams, players, cups) => {
  // Promotion and demotion prep
  const newTeams = []
  const promotions = []
  const relegations = []
  const milestones = []
  leagues.forEach((league, leagueId) => newTeams.push([]))

  leagues.forEach((league, leagueId) => {
    const sortedSeasonTeams = sortLeagueTeams(seasons[leagueId][0].teams)

    sortedSeasonTeams.forEach((seasonTeam, finishPosition) => {
      if (finishPosition < teamsToMoveEachWay) {
        if (finishPosition === 0) {
          milestones.push({leagueId, teamId: seasonTeam.teamId, milestone: "champion"})
        } else if (league.promotionId !== undefined) {
          milestones.push({leagueId, teamId: seasonTeam.teamId, milestone: "promoted"})
        }
        const newTeamLeagueId = (league.promotionId !== undefined) ? league.promotionId : leagueId
        newTeams[newTeamLeagueId].push(seasonTeam)

        if (league.tier > 1) {
          promotions.push(seasonTeam.teamId)
        }
      } else if (league.relegationId !== undefined && finishPosition > 19 - teamsToMoveEachWay) {
        // Demotion
        newTeams[league.relegationId].push(seasonTeam)
        milestones.push({leagueId, teamId: seasonTeam.teamId, milestone: "relegated" })
        relegations.push(seasonTeam.teamId)
      }
      else {
        newTeams[leagueId].push(seasonTeam)
      }
    })
  })

  return { newTeams, promotions, relegations, milestones }
}

// Move loan players back to their respective clubs
// This assumes all loans are until the end of the current season
const getExpiredLoans = (players) =>
   players.filter(player => player.borrowingTeamId !== null)

export {
  generateNews,
  generateLeaguePrizeMoney,
  generateCupYears,
  generateSeasonTeams,
  getExpiredLoans
}
