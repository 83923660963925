const updateCupTeams = (home, away, homeScore, awayScore) => {
  if (homeScore > awayScore) {
    away.knockedOut = true
  } else {
    // TODO: Ensure cup matches (not here) go to knockout (no draw allowed) or aggregate
    home.knockedOut = true
  }
}

export {
  updateCupTeams
}
