import teamsReducer from './teams';
import playersReducer from './players';
import matchReducer from './matches';
import leaguesReducer from './leagues';
import seasonsReducer from './seasons';
import fixturesReducer from './fixtures';
import userReducer from './user';
import marketReducer from './market';
import boardReducer from './board';
import milestonesReducer from './milestones';
import playersHistoryReducer from './playersHistory';
import cupsReducer from './cups';
import cupYearsReducer from './cupYears';

import {combineReducers} from 'redux';

const allReducers = combineReducers({
    matches : matchReducer,
    teams : teamsReducer,
    players : playersReducer,
    leagues: leaguesReducer,
    seasons: seasonsReducer,
    fixtures : fixturesReducer,
    user : userReducer,
    market : marketReducer,
    board : boardReducer,
    milestones: milestonesReducer,
    playersHistory: playersHistoryReducer,
    cups: cupsReducer,
    cupYears: cupYearsReducer,
});

export default allReducers;
