import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { releasePlayer as releasePlayerLocal, togglePlayerTransferStatus, togglePlayerLoanStatus, addNews as addNewsLocal, extendPlayer as extendPlayerLocal } from "../actions"
import abbreviatePosition from "../functions/abbreviatePosition"
import { makeNews } from "../functions/addNews"
import Modal from "react-modal"
import { getPlayerRating } from "../functions/players"
import PlayerDetail from "./PlayerDetail"
import sortPlayers from "../functions/sortPlayers"
import addSortedClass from "../functions/addSortedClass"
import workerSend from "../appWorker"
import Button from "./Button"

function PlayerContracts() {
  const user = useSelector(state => state.user)
  const team = useSelector(state => state.teams[user.teamId])
  const players = useSelector(state => state.players)
  const dispatch = useDispatch()

  const setPlayerToRelease = (playerId) => { _setPlayerToRelease((playerToRelease !== playerId) ? playerId : false) }
  let [playerToRelease, _setPlayerToRelease] = useState(false)
  const setPlayerToExtend = (playerId) => { _setPlayerToExtend((playerToExtend !== playerId) ? playerId : false) }
  let [playerToExtend, _setPlayerToExtend] = useState(false)
  let [extensionYears, setExtensionYears] = useState(3)
  let [sortBy, setSortBy] = useState('contractExpiry')
  const [viewPlayer, setViewPlayer] = useState(false)
  const usersPlayers = players.filter(player => player.teamId === team.id)
  const weeklyWages = usersPlayers.reduce(((acc, player) => acc += player.wage), 0)
  const totalWages = usersPlayers.reduce(((acc, player) => acc += (player.wage * 52 * player.contractExpiry)), 0)
  const averageRating = usersPlayers.reduce(((acc, player) => acc += Math.floor(getPlayerRating(player) / usersPlayers.length)), 0)
  const sortedPlayers = sortPlayers(usersPlayers, sortBy)

  const releasePlayer = (playerId) => {
    if (user.isLocalGame) {
      dispatch(releasePlayerLocal(playerToRelease, user.teamId, playerToRelease.contractExpiry * playerToRelease.wage))
      dispatch(addNewsLocal([
        makeNews(user.currentTime,
          team.id,
          `${playerToRelease.firstName} ${playerToRelease.lastName} Released by ${team.shortName}`,
          "",
          "transfer"
        )]))
    } else {
      workerSend("releasePlayer", { playerId })
    }
  }

  const extendPlayer = (playerId) => {
    if (user.isLocalGame) {
      dispatch(extendPlayerLocal(playerToExtend, extensionYears))
      dispatch(addNewsLocal([
        makeNews(user.currentTime,
          team.id,
          `${playerToExtend.firstName} ${playerToExtend.lastName} Extended by ${team.shortName} for ${extensionYears} years`,
          "",
          "transfer"
        )]))
    } else {
      workerSend("extendPlayer", { playerId, years: extensionYears })
    }
  }

  const toggleTransferStatus = (player) => {
    if (user.isLocalGame) {
      dispatch(togglePlayerTransferStatus(player))
    } else {
      workerSend("toggleTransferStatus", { playerId: player.id })
    }
  }

  const toggleLoanStatus = (player) => {
    if (user.isLocalGame) {
      dispatch(togglePlayerLoanStatus(player))
    } else {
      workerSend("toggleLoanStatus", { playerId: player.id })
    }
  }

  const releaseOptions = (playerId) => {
    return (
      <>
        The cost to cancel {playerToRelease.firstName} {playerToRelease.lastName + "'s"} contract is £{(playerToRelease.contractExpiry * playerToRelease.wage).toLocaleString()}
        <div className="cta-holder--r">
          <Button onClickFunction={() => { setPlayerToRelease(false); releasePlayer(playerId) }} label={`Release ${playerToRelease.firstName} ${playerToRelease.lastName}`} />
          <Button onClickFunction={() => setPlayerToRelease(false)} label='Close' />
        </div>
      </>
    )
  }

  const extendOptions = (playerId) => {
    return (
      <div ariaHideApp={false} isOpen={playerToExtend.id >= 0}>
        Extend {playerToRelease.firstName} {playerToRelease.lastName} for {extensionYears} years
        <div className="cta-holder--r">
          <input className="input--text" type="number" name="" id="" min="1" max="5" placeholder={extensionYears} value={extensionYears} onChange={e => setExtensionYears(parseInt(e.target.value))} />
          <Button onClickFunction={() => { setPlayerToExtend(false); extendPlayer(playerToExtend.id); }} label={`Extend ${playerToExtend.firstName} ${playerToExtend.lastName}`} />
          <Button onClickFunction={() => setPlayerToExtend(false)} label='Close' />
        </div>
      </div>
    )
  }

  return (
    <div className="p-1 s:p-2 m:p-3 l:p-4">
      {viewPlayer &&
        <Modal ariaHideApp={false} isOpen={viewPlayer.id >= 0}>
          {<PlayerDetail player={viewPlayer} />}
          <button onClick={() => setViewPlayer(false)}>Close</button>
        </Modal>
      }
      <header className="center">
        <h1 className="mb-4 text-2xl font-bold font-sans tracking-widest text-center">Contracts</h1>
      </header>
      <ul className="bosh">
        <li><span>Players</span><span>{sortedPlayers.length}</span></li>
        <li><span>Weekly Salary</span><span>{weeklyWages.toLocaleString()}</span></li>
        <li><span>Total Salary</span><span>{totalWages.toLocaleString()}</span></li>
        <li><span>Average Rating</span><span>{averageRating}</span></li>
      </ul>
      <table className="table-- card">
        <thead>
          <tr>
            <th></th>
            <th className={addSortedClass(sortBy, 'lastName')} onClick={() => setSortBy('lastName')}>Name</th>
            <th className={addSortedClass(sortBy, 'position')} onClick={() => setSortBy('position')}><abbr title="Position">POS</abbr></th>
            <th className={addSortedClass(sortBy, 'age')} onClick={() => setSortBy('age')}>Age</th>
            <th className={addSortedClass(sortBy, 'wage')} onClick={() => setSortBy('wage')}><abbr title="Weekly Wage">Weekly</abbr></th>
            <th className={addSortedClass(sortBy, 'contractExpiry')} onClick={() => setSortBy('contractExpiry')}><abbr title="Contract Length in Years">YRS</abbr></th>
            <th>Rating</th>
            <th className={addSortedClass(sortBy, 'transferListed')} onClick={() => setSortBy('transferListed')}><abbr title="Transfer Status">Transfer</abbr></th>
            <th className={addSortedClass(sortBy, 'loanListed')} onClick={() => setSortBy('loanListed')}><abbr title="Loan Status">Loan</abbr></th>
            <th>Release</th>
            <th>Extend</th>
          </tr>
        </thead>
        <tbody>
          {
            sortedPlayers.map(player => (
              <React.Fragment key={getUniqueKey()}>
                <tr>
                  <td className="c">{player.loanListed && <i className="icon--loan-listed"></i>}{player.transferListed && <i className="icon--transfer-listed"></i>}</td>
                  <td className="onClick" onClick={() => setViewPlayer(player)}>{`${player.firstName} ${player.lastName}`}</td>
                  <td>{abbreviatePosition(player.position)}</td>
                  <td>{player.age}</td>
                  <td className="accounting">{player.wage.toLocaleString()}</td>
                  <td>{player.contractExpiry}</td>
                  <td>{getPlayerRating(player)}</td>
                  <td>
                    <select value={player.transferListed ? "✓" : "⤫"} onChange={() => toggleTransferStatus(player)}>
                      <option value="⤫">⤫</option>
                      <option value="✓">✓</option>
                    </select>
                  </td>
                  <td>
                    <select value={player.loanListed ? "✓" : "⤫"} onChange={() => toggleLoanStatus(player)}>
                      <option value="⤫">⤫</option>
                      <option value="✓">✓</option>
                    </select>
                  </td>
                  <td className="relative">
                    <Button label='Release' onClickFunction={() => { setPlayerToExtend(false); setPlayerToRelease(player) }} className={needsCallToAction(player.contractExpiry)} />
                  </td>
                  <td>
                    <Button label='Extend' onClickFunction={() => { setPlayerToRelease(false); setPlayerToExtend(player) }} className={needsCallToAction(player.contractExpiry)} />
                  </td>
                </tr>
                {((playerToRelease && playerToRelease.id === player.id) || (playerToExtend && playerToExtend.id === player.id)) &&
                  <tr>
                    <td colSpan="10" className="p1">
                      {playerToRelease && playerToRelease.id === player.id && releaseOptions(playerToRelease.id)}
                      {playerToExtend && playerToExtend.id === player.id && extendOptions()}
                    </td>
                  </tr>
                }
              </React.Fragment>
            ))}
        </tbody>
      </table>
    </div>
  )
}

export default PlayerContracts

const needsCallToAction = contractExpiry => contractExpiry > 0 ? "btn third" : "cta--accept"
const getUniqueKey = () => Math.round(new Date().getTime() * Math.random())
