import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import MatchResultDetail from "./MatchResultDetail"
import { getMatch } from "../reducers/matches"
import getCssClassName from "../functions/getCssClassName"
import winLoseDraw from "../functions/winLoseDraw"
import moment from "moment"

function Results() {
  const user = useSelector(state => state.user)
  const matches = useSelector(state => state.matches)
  const teams = useSelector(state => state.teams)
  const players = useSelector(state => state.players)
  const leagues = useSelector(state => state.leagues)
  const cups = useSelector(state => state.cups)
  const team = useSelector(state => state.teams[user.teamId])
  const [isFiltered, setFiltered] = useState(true)
  let { id } = useParams()
  const matchDetail = id === undefined ? false : getMatch(matches, id) || false

  const filteredMatches = !isFiltered ? matches : matches.filter(match => {
    return match.homeId === team.id || match.awayId === team.id
  })

  const filterHeader = (
    <header>
      <h1 className="mb-4 text-2xl font-bold font-sans tracking-widest text-center">{isFiltered ? team.shortName + ' Results' : 'All Results'}</h1>
      <button onClick={() => setFiltered(!isFiltered)} className="btn first">
        {!isFiltered ? team.name + ' Results' : 'All Results'}
      </button>
    </header>
  )

  const MatchResultsList = (
    <React.Fragment>
      {filterHeader}
      <div className="results-list">
        <div className="card">
          <table className="table--">
            <thead>
              <tr>
                <th className="a-r">Home</th>
                <th className="center">Score</th>
                <th className="a-l">Away</th>
              </tr>
            </thead>
            <tbody>
              {
                filteredMatches.map((match, i) => {
                  const competition = match.leagueId !== undefined
                    ? leagues[match.leagueId]
                    : cups[match.cupId]
                  let homeTeam = teams[match.homeId]
                  let awayTeam = teams[match.awayId]
                  return (
                    <tr key={i}>
                      <td className={getCssClassName(`${homeTeam.shortName}-${homeTeam.logo}`, "right")}>{isFiltered ? <span className={winLoseDraw(team.id, match.homeId, match.homeScore, match.awayScore) + "--inline"}></span> : ""} {homeTeam.name}</td>
                      <td className="center">
                        <p className="fixture-row__competition">({competition.name})</p>
                        <p className="fixture-row__date">{moment(match.kickOff).format("MMM D, YYYY")}</p>
                        <p className="fixture-row__score"><Link to={"/matchdetail/" + match.id}>{match.homeScore} - {match.awayScore}</Link></p>
                        <p className="fixture-row__attendance">{match.attendance.toLocaleString()}</p>
                      </td>
                      <td className={getCssClassName(`${awayTeam.shortName}-${awayTeam.logo}`)}>{awayTeam.name}</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </React.Fragment>
  )

  const MatchResultsDetail = (matchDetail) => (
    <React.Fragment>
      <MatchResultDetail match={matchDetail} players={players} homeTeam={teams[matchDetail.homeId]} awayTeam={teams[matchDetail.awayId]}></MatchResultDetail>
      <footer className="action">
        <Link to="/results" className="btn first">
          Back to Results List
        </Link>
      </footer>
    </React.Fragment>
  )

  const MatchResults = (
    <React.Fragment>
      {matchDetail ? MatchResultsDetail(matchDetail) : MatchResultsList}
    </React.Fragment>
  )

  return (
    <div className="p-1 s:p-2 m:p-3 l:p-4">
      {MatchResults}
    </div>
  )
}

export default Results;
