import { getLeagueStartDate } from "../functions/generateLeagueFixtures"
import { getLastFixtureForCup } from "../functions/matches"
import { getDateWithAddedDays } from "../functions/generateLeagueFixtures"

const generateAllCupFixtures = (cups, teams, matches, cupYears, fixtures) => {
  let newFixtures = []
	cups.forEach(cup => {
		const cupYear = cupYears[cup.id][0]
		if (cupYear && cupYear.teams.length > 0 && (!fixtures[cup.id] || fixtures[cup.id].length === 0)) {
			const lastFixture = getLastFixtureForCup(matches, cup.id, cupYear.year)
			let startDate
			if (lastFixture) {
				startDate = getDateWithAddedDays(lastFixture.kickOff, cup.daysBetweenRounds)
			} else if (cupYear.teams) {
				startDate = getDateWithAddedDays(getLeagueStartDate(cupYear.year), cup.dayOffset + cup.daysFromSaturday)
			}
			if (startDate) {
				newFixtures[cup.id] = generateCupFixtures(
					cup.id,
					startDate,
					teams,
					cupYear.year,
					cupYear.teams
				)
			}
		}
	})
  return newFixtures
}

const generateCupFixtures = (cupId, fixtureDate, teams, competitionYear, cupTeams) => {
  let newFixtures = []
  const remainingTeams = cupTeams.filter(cupTeam => !cupTeam.knockedOut)
  if (remainingTeams.length < 2) return newFixtures
  remainingTeams.forEach((team, i) => {
    if (!(i % 2)) {
      let homeId = team.teamId
      let awayId = remainingTeams[i+1].teamId

      let id = Math.round(fixtureDate.getTime()/1000) + "c" + cupId + homeId + awayId
      newFixtures.push({id, cupId, competitionYear, homeId, homeName: teams[homeId].name, awayId, awayName: teams[awayId].name, kickOff: fixtureDate.toISOString()})
    }
  })
  return newFixtures
}

export default generateAllCupFixtures;
