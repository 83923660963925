const matchReducer = (state = [], action) => {
  let matches
  const newData = action.matches

  switch(action.type){
    case 'newGame':
      return []
    case 'gameJoined':
      return action.matches
    // case 'incrementalServerData':
    //   if (newData !== undefined) {
    //     matches = [...state]
    //     newData.forEach((match, i) => {
    //       matches.unshift(match)
    //     });
    //     return matches
    //   }
    //   return state
    case 'incrementalServerData':
      return (newData !== undefined) ? newData : state
    case 'fullServerData':
      return (newData !== undefined) ? newData : []
    case 'matchPlayed' :
        matches = state.slice(0, 500) // Store only the past 500 matches + this round
        const result = action.result
        const match = {
            id: result.fixture.id,
            homeId: result.home.id,
            awayId: result.away.id,
            homePlayerIds: result.homePlayerIds,
            awayPlayerIds: result.awayPlayerIds,
            homeScore: result.homeScore,
            awayScore : result.awayScore,
            homeScorers: result.homeScorers,
            awayScorers : result.awayScorers,
            kickOff: result.fixture.kickOff,
            competitionYear: result.fixture.competitionYear,
            attendance: result.attendance,
            leagueId: result.fixture.leagueId,
            cupId: result.fixture.cupId,
            homeSalary: result.homeSalary,
            awaySalary: result.awaySalary,
        }

        matches.unshift(match)
        return matches

    default:
        return state;
  }
}

export const getLastMatchForTeam = (matches, thisTeamId) => {
    if (!matches || matches.length === 0) return null
    // This returns the first matching match
    // Not the latest
    return matches.find(team => team.homeId === thisTeamId || team.awayId === thisTeamId)
}

export const getLastMatchesForLeague = (leagueId, howMany, matches) => {
  return matches.filter(match => match.leagueId === leagueId).splice(0, 10*howMany)
}

export const getMatch = (matches, id) => {
  return matches.find(match => match.id === id)
}

export default matchReducer;
