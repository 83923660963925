import randomNo from "../functions/randomNo"
import marketValue from "../functions/marketValue"

const progressPlayerYear = (player, gameYears) => {
  player.age++
  player.form = 50
  player.rating = { ...player.rating }
  if (player.age > 30) {
    player.rating.physical -= 10
  } else {
    player.rating.physical++
  }
  player.rating.mental++
  capStats(player)

  if (player.teamId === -1) {
    // Set the wage of the free agents based on the marketValue()
    player.wage = marketValue(player, gameYears)
  } else {
    // Decrement the contractExpiry years for contracted players
    player.contractExpiry--
  }
}

const updatePlayerTangibles = (player, scorer, netGoals) => {
  // TODO: Could store (add to) cumulative player history here, as we know the goals
  !player.appearances ? player.appearances = 1 : player.appearances++

  let formChange = 0
  if (scorer !== undefined) {
    // Scoring a goal boosts form.
    // TODO an equivalent / some compensation for goalkeeper/defender/midfielder
    formChange += Math.min(scorer.goals, 3) * 0.51
    !player.goals ? player.goals = scorer.goals : player.goals += scorer.goals
  }

  // This will vary in future but is standard for the timebeing
  player.condition -= 15

  // Win and lose affect form
  formChange += netGoals > 0 ? 0.51 : netGoals < 0 ? -0.51 : 0

  // Momentum can be broken - hard
  if (player.form > 70 && formChange < 0) {
    player.form += formChange * 1.7
  // Bad streak turnaround feels better
  } else if (player.form < 30 && formChange > 0) {
    player.form += formChange * 1.7
  } else {
    player.form += formChange
  }
  // Hard stop at 20 - below this becomes meaningless and difficult to calculate effects for
  if (player.form < 20) player.form = 20

  capStats(player)
}

const playerTraining = (team, player) => {
  // TODO something based on team training decisions
  player.condition += playerRecovery()
  capStats(player)
}

const capStats = player => {
  player.condition = capStat(player.condition, 5, 100)
  player.form = capStat(player.form, 10, 100)
  player.rating.physical = capStat(player.rating.physical, 10, 100)
  player.rating.mental = capStat(player.rating.mental, 10, 100)
}

export const capStat = (stat, min, max) => {
  return Math.min(Math.max(Math.round(stat), min), max)
}

const playerRecovery = () => randomNo(12) - 5

const getPlayerRating = player => {
  return Math.floor((player.rating.physical + player.rating.mental + player.rating.technical) / 3)
}

const getPlayersFromSquadOrder = ((squadOrder, players) => squadOrder.slice(0, 16).map(id => players[id]))

export {
  progressPlayerYear,
  updatePlayerTangibles,
  playerTraining,
  capStats,
  getPlayerRating,
  getPlayersFromSquadOrder,
}
