const ws = {
  wsUrl: null,
  wsCallback: null,
  onMessage: function (message) {
    this.wsCallback(JSON.parse(message.data));
  },
  connection: null,
  connect: function () {
    this.connection = new WebSocket(this.wsUrl)
    this.connection.onmessage = (message) => this.onMessage(message)
  },
  afterOpen: function(noStore, data, action) {
    if (!noStore) this.wsCallback({type: "connected"})
    if (action !== "appInit") this._send(JSON.stringify({...data, action: "appInit"}), "appInit")
    this._send(data, action)
  },
  _send: function (data, action) {
    this.connection.onmessage = (message) => this.onMessage(message)
    this.connection.send(data)
    if (action === 'leaveGame') this.wsCallback({type: "leaveGame"})
  },
  send: async function (url, callback, action, payload, noStore, user) {
    this.wsCallback = callback
    if (this.wsUrl !== url) {
      this.wsUrl = url
      console.log(`Connecting to ${this.wsUrl}`)
      this.connect()
    } else {
      console.log(`Using ${this.wsUrl}`);
    }
    if (!noStore) this.wsCallback({type: "connecting"})
    let data = JSON.stringify({ userId: user.id, gameId: user.gameId, ...payload, action })
    //this.wsCallback({type: "debug", data})
    if (!this.connection || this.connection.readyState === WebSocket.CLOSING || this.connection.readyState === WebSocket.CLOSED) {
      while (this.connection && this.connection.readyState === WebSocket.CLOSING) {
        await new Promise(resolve => setTimeout(resolve, 200))
      }
      this.connect()
      this.connection.onopen = () => this.afterOpen(noStore, data, action)
    } else if (this.connection.readyState === WebSocket.CONNECTING) {
      this.connection.onopen = () => this.afterOpen(noStore, data, action)
    } else {
      this._send(data, action)
    }
  },
  onopen: function (func) { this.connection.onopen = func },
}

if (typeof window !== "undefined") window.ws = ws
