import React, { useState } from "react"
import { Link } from "react-router-dom"
import { useSelector } from "react-redux"
import getCssClassName from "../functions/getCssClassName"
import nextTurn, { isUserMatchDay, isPreSeason } from "../localFunctions/nextTurn"

function Masthead() {
	const user = useSelector(state => state.user)
	const isNews = user.news.filter(item => item.archived === false)
	const teams = useSelector(state => state.teams)
	const team = useSelector(state => state.teams[user.teamId])
	const [askedPermission, setAskedPermission] = useState(Notification.permission === 'granted' || Notification.permission === 'denied')

	const requestPermission = () => {
		Notification.requestPermission(function (status) {
			setAskedPermission(status === 'granted' || status === 'denied')
		});
	}

	const spinner = () => user.ready && <span className="rotating">X</span>

	return (
		<header className="flex flex-col m:flex-row gap-4 justify-between p-4 masthead">
			<section className="flex flex-row m:basis-1/2 justify-between items-center">
				<h1 className={
					(user.teamId > -1 ? getCssClassName(
						`${teams[user.teamId].shortName}-${teams[user.teamId].logo
						}` + (user.serverConnecting && "-loading")
					) : (user.teamId === -1 && user.serverConnecting && " rotating"))
					+ " team-name logo mr-4 font-sans font-black text-3xl flex items-center"
				}>
					<Link to="/" className="no-underline">
						<span>U</span>
						<span className="hidden s:inline">nbeaten </span>
						<span>R</span>
						<span className="hidden s:inline">un</span>
					</Link>
					{
						user.teamId > -1
						&& teams[user.teamId].unbeatenRun > 0
						&& <span className="items-center flex justify-center h-8 w-8 bg-violet-100 rounded-full">
							<span className="font-serif italic font-medium text-lg leading-[0]">
								{teams[user.teamId].unbeatenRun}
							</span>
						</span>
					}
				</h1>
				{team && (
					<>
						<Link to="/">
							<span className="inbox-link">
								<svg
									viewBox="0 0 16 16"
									className="icon-inbox bi bi-envelope"
									fill="currentColor"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fillRule="evenodd"
										d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2zm13 2.383l-4.758 2.855L15 11.114v-5.73zm-.034 6.878L9.271 8.82 8 9.583 6.728 8.82l-5.694 3.44A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.739zM1 11.114l4.758-2.876L1 5.383v5.73z"
									/>
								</svg>
								{isNews.length > 0 && (
									<span className="notification--bubble text-center radius-50 color-white inline-block font-mono foont-normal p-1 rounded-full leading-4 text-white absolute -top-[2ch] -right-[2ch] bg-orange">
										{isNews.length}
									</span>
								)}
							</span>
						</Link>
					</>
				)}

			</section>
			{user.teamId > -1 &&
				<>
					<nav className="text-right">
						<span className="game-date">
							{new Date(user.currentTime).toDateString()}
						</span>
						{!askedPermission && !user.isLocalGame && <button onClick={() => requestPermission()} className="btn second">Enable Notifications</button>}
						{isUserMatchDay() ? <Link className="btn first" to="/select-team">Match Prep {spinner}</Link> :
							<Link className="btn first" onClick={() => nextTurn()}>Next Day {spinner}</Link>}
						{isPreSeason() && <Link className="btn second" onClick={() => nextTurn(true)}>{`End pre-season`}{user.fastForward && <span className="rotating">X</span>}</Link>}
						{user.gameWaiting && <span className="warning">Game waiting on you!</span>}
					</nav>
				</>
			}
		</header>
	)
}

export default Masthead
