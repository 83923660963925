import winLoseDraw from "../functions/winLoseDraw"
import boardData from "../data/boards";
import { capStats } from "../functions/board"

const boardReducer = (state = boardData, action) => {
  const board = [...state]
  const newData = action.board

  switch (action.type) {
    case "newGame":
      return boardData
    case 'gameJoined':
      return action.board
    case 'incrementalServerData':
      return (newData !== undefined) ? newData : state
    case 'fullServerData':
      return (newData !== undefined) ? newData : []
    case "matchPlayed":
      let { homeScore, awayScore } = action.result
      let { homeId, awayId } = action.result.fixture

      const homeResult = winLoseDraw(homeId, homeId, homeScore, awayScore);

      board[homeId] = {...board[homeId]}
      board[awayId] = {...board[awayId]}
      const goalDifference = Math.abs(homeScore-awayScore)

      if (homeResult === "win") {
        board[homeId].confidence += goalDifference;
        board[awayId].confidence -= goalDifference;
      } else if (homeResult === "lose") {
        board[homeId].confidence -= goalDifference;
        board[awayId].confidence += goalDifference;
      }

      capStats(board[homeId])
      capStats(board[awayId])

      return board;
    default:
      return state;
  }
};



export default boardReducer;
