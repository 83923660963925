import playersHistoryData from "../data/playersHistory"

const playersHistoryReducer = (state = [], action) => {
    let playersHistory = [...state]
    const newData = action.playersHistory

    switch(action.type) {
        case 'newGame':
          return playersHistoryData
        case 'gameJoined':
          return action.playersHistory
        case 'incrementalServerData':
          return (newData !== undefined) ? newData : state
        case 'fullServerData':
          return (newData !== undefined) ? newData : []
        case 'updatePlayerHistory' :
          const { playerId, playerHistory } = action
          playersHistory[playerId] = playerHistory
          return playersHistory
        case 'nextYear':
          // Generate some new players
          for (let i = 0; i < 100; i++) playersHistory.push([])
          return playersHistory
        default :
            return state
    }
}
export default playersHistoryReducer

export const getPlayerTeamHistory = (playersHistory, playerId, teamId) =>
  playersHistory[playerId].filter(history => history.teamId === teamId)

export const getPlayerTeamLatestSeasonHistory = (playersHistory, playerId, teamId) =>
  getPlayerTeamHistory(playersHistory, playerId, teamId)
      .filter(history => history.competitionType === 'league')
      .sort((a, b) => a.competitionYear < b.competitionYear ? -1 : 1)[0]
