import React, { useState, useEffect, button } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom"
import { newGame, clubSelected, addLeagueFixtures, leaveGameLocal } from "../actions";
import { generateFirstLeagueFixtures } from "../functions/generateLeagueFixtures"
import getUniqueID from "../functions/getUniqueID"
import { getPlayerRating } from "../functions/players"
import getCssClassName from "../functions/getCssClassName"
import workerSend from "../appWorker"
import { apiUrl } from '../config'
import Button from './Button'
import TextInput from './TextInput'
import Label from './Label'

const JoinOrCreateGame = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const teams = useSelector(state => state.teams)
  const user = useSelector(state => state.user)
  const leagues = useSelector(state => state.leagues)
  const seasons = useSelector(state => state.seasons)
  const players = useSelector(state => state.players)
  const [managerName, _setManagerName] = useState("")
  const setManagerName = (evt) => _setManagerName(evt.target.value)
  const [games, setGames] = useState([])
  const [toggleFetchGames, setToggleFetchGames] = useState(false)

  async function fetchGames() {
    try {
      const data = await fetch(`${apiUrl}/games`)
      setGames(await data.json())
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    (async function () {
      if (!user.gameId) {
        await fetchGames()
        await new Promise(resolve => setTimeout(resolve, 5000))
        if (!user.gameId) setToggleFetchGames(!toggleFetchGames)
      }
    })()
  }, [user.gameId, toggleFetchGames])

  const leaveGame = () => {
    if (user.isLocalGame) {
      dispatch(leaveGameLocal())
    } else {
      workerSend("leaveGame")
    }
  }

  const localGame = () => {
    dispatch(newGame(getUniqueID(), managerName))
  }

  function joinGame(chosenId) {
    workerSend("joinGame", { gameId: chosenId })
  }

  const selectOnlineTeam = (teamId) => {
    workerSend("claimTeam", {
      teamId,
      managerName
    })
  }
  const selectTeam = (teamId) => {
    if (user.isLocalGame) {
      dispatch(addLeagueFixtures(generateFirstLeagueFixtures(leagues, seasons, teams)))
      dispatch(clubSelected(user.id, teamId))
    } else {
      selectOnlineTeam(teamId)
    }
    history.push('/')
  }

  const existingGamesList =
    games.map((id) =>
      <button key={`joinGame-${id}`} onClick={() => joinGame(id)}>{id}</button>
    )

  const checkForName = (str) => str === "" ? true : false

  const startGameForm = (
    <div className='flex flex-col m:flex-row items-center gap-4 s:gap-8'>
      <section className='flex flex-col m:flex-row gap-4 items-center'>
        <Label
          htmlFor="manager-name"
          textInside="Enter your manager name"
          required={checkForName(managerName)}
        />
        <TextInput
          name="manager-name"
          placeholder="John Smith"
          onChangeFunction={setManagerName}
          required={checkForName(managerName)}
        />
      </section>
      <section className='flex flex-col m:flex-row gap-4'>
        <h1 className="text-2xl font-bold font-sans tracking-widest text-center">Game Type</h1>
        <section className='flex gap-4 justify-center'>
          <Button disabled={checkForName(managerName)} onClickFunction={() => joinGame(getUniqueID())} label='Online game' />
          <Button disabled={checkForName(managerName)} onClickFunction={() => localGame()} label='Offline game' />
        </section>
      </section>
      {!!games.length && (
        <>
          <hr className="divider" />
          <h1 className="mb-4 text-2xl font-bold font-sans tracking-widest text-center">Join Game</h1>
          {existingGamesList}
        </>
      )}
    </div>
  )

  const teamPlayers = (teamId) => players.filter(player => player.teamId === teamId)
  const averageRating = (teamPlayers) => teamPlayers.reduce(((acc, player) => acc += Math.floor(getPlayerRating(player) / teamPlayers.length)), 0)

  const selectClubForm = (
    <>
      <header className="flex justify-between content-center mb-4">
        <h1 className="text-2xl font-bold font-sans tracking-widest text-center">Select Your Club</h1>
        <Button onClickFunction={() => leaveGame()} label='Go Back' />
      </header>
      <div className="grid m:grid-cols-2 l:grid-cols-3 xl:grid-cols-4 gap-4 xl:gap-8">
        {leagues.map((league, i) =>
          <React.Fragment key={league.id}>
            <div className="flex flex-col items-center justify-center rounded border bg-slate-900 p-4">
              <h3 className='text-slate-100 font-serif text-xl'>{league.name}</h3>
            </div>
            {
              teams.filter(team => team.tier === league.tier && !team.userId)
                .map(team =>
                  <div key={team.id} className="flex flex-col rounded border border-slate-300 p-4">
                    <section className='flex justify-between'>
                      <section className='w-1/2'>
                        <h3 className={getCssClassName(`${team.shortName}-${team.logo}`) + ` zero`}>{team.name}</h3>
                      </section>
                      <section className='w-1/2'>
                        <div className="stars" style={{ "--rating": averageRating(teamPlayers(team.id)) / 20 }} aria-label="Rating"></div>{/* Want this centred with a little more bottom margin*/}
                      </section>
                    </section>
                    <section className='flex'>
                      <section className='w-1/2'>
                        <p className='tracking-widest uppercase text-xxs mt-4'>Stadium</p>
                        <p className='font-serif text-xl'>{team.stadium.name}</p>
                        <p className="-mt-1 "><em className='font-serif'>{team.stadium.capacity.toLocaleString()} capacity</em></p>{/* Want this closer to stadium name and further from bank*/}
                      </section>
                      <section className='w-1/2'>
                        <p className='tracking-widest uppercase text-xxs mt-4'>Finances</p>
                        <p>Bank: ${team.bankBalance.toLocaleString()}</p>
                      </section>
                    </section>
                    <div className="a-r">
                      <Button onClickFunction={() => selectTeam(team.id)} className="btn first" label={`Manage ${team.shortName}`} />
                    </div>
                  </div>
                )
            }
          </React.Fragment >
        )}
      </div>
    </>
  )

  return (
    <div className="p-1 s:p-2 m:p-3 l:p-4">
      {user.gameId && selectClubForm}
      {!user.gameId && startGameForm}
    </div>
  )
}
export default JoinOrCreateGame
