import leaguesData from "../data/leagues"
import cupsData from "../data/cups"
import { getFixtureGroupPos, getFixturePos } from "../functions/fixtures"

const initialState = {
  leagues: leaguesData.map(league => []),
  cups: cupsData.map(cup => []),
}

const fixturesReducer = (state = initialState, action) => {
  let fixtures = {...state}
  const newData = action.fixtures

  switch(action.type){
    case "newGame":
      return initialState
    case 'gameJoined':
      return action.fixtures
    case 'incrementalServerData':
      return (newData !== undefined) ? newData : state
    case 'fullServerData':
      return (newData !== undefined) ? newData : {}
    case 'matchPlayed' :
      // Remove played match from fixtures
      const fixture = action.result.fixture
      let competitionId, allFixtures, competitionFixtures
      if (fixture.cupId !== undefined) {
        competitionId = fixture.cupId
        allFixtures = [...fixtures.cups]
        fixtures.cups = allFixtures
        competitionFixtures = [...allFixtures[competitionId]]
        allFixtures[competitionId] = competitionFixtures
        let fixturePos = getFixturePos(competitionFixtures, fixture)
        competitionFixtures.splice(fixturePos, 1)
      } else {
        competitionId = fixture.leagueId
        allFixtures = [...fixtures.leagues]
        fixtures.leagues = allFixtures
        competitionFixtures = [...allFixtures[competitionId]]
        allFixtures[competitionId] = competitionFixtures
        let fixtureGroupPos = getFixtureGroupPos(competitionFixtures, fixture)
        let fixturePos = getFixturePos(competitionFixtures[fixtureGroupPos], fixture)
        competitionFixtures[fixtureGroupPos] = [...competitionFixtures[fixtureGroupPos]]
        competitionFixtures[fixtureGroupPos].splice(fixturePos, 1)
        if (competitionFixtures[fixtureGroupPos].length === 0) {
          competitionFixtures.splice(fixtureGroupPos, 1)
        }
      }
      return fixtures
    case 'addCupFixtures' :
      fixtures.cups = [...fixtures.cups]
      action.fixtures.forEach((newFixtures, cupId) => {
        fixtures.cups[cupId] = newFixtures
      });
      return fixtures
    case 'addLeagueFixtures' :
      fixtures.leagues = [...fixtures.leagues]
      action.fixtures.forEach((newFixtures, leagueId) => {
        fixtures.leagues[leagueId] = newFixtures
      });
      return fixtures
    default:
      return state
  }
}

export default fixturesReducer
