import React from "react"
const Label = (props) => {
    const { textInside, htmlFor, required } = props
    return <label
        htmlFor={htmlFor}
        className="tracking-widest uppercase text-xxs"
    >
        {required && <span className="text-red-600">*</span>}
        {textInside}
    </label>
}
export default Label