import React from "react";
const Button = (props) => {
    let { label, selected, onClickFunction, disabled } = props
    if (disabled)
        return <button
            className={`bg-slate-100 rounded px-2 py-2 leading-3 transition-all text-xxs uppercase tracking-widest`}
            disabled={disabled}
        >
            {label}
        </button>
    selected = (selected === "selected")
        // selected button
        ? "bg-violet-900 text-white"
        // unselected
        : " bg-violet-100 text-violet-900 hover:text-white hover:bg-violet-900";
    return <button
        className={`${selected} rounded px-2 py-2 leading-3 transition-all text-xxs uppercase tracking-widest`}
        onClick={onClickFunction}
    >
        {label}
    </button>
}
export default Button;