import React from "react";
import { useSelector } from "react-redux";
import MatchResultDetail from "./MatchResultDetail"
import { getLastMatchForTeam } from "../reducers/matches"

export default function MatchResult() {
  const user = useSelector(state => state.user)
  const teams = useSelector(state => state.teams)
  const players = useSelector(state => state.players)
  const matches = useSelector(state => state.matches);

  const match = getLastMatchForTeam(matches, user.teamId)
  if (!match) return null

  return (
    <div className="p-1 s:p-2 m:p-3 l:p-4">
      <MatchResultDetail match={match} players={players} homeTeam={teams[match.homeId]} awayTeam={teams[match.awayId]}></MatchResultDetail>
    </div>
  );
}
