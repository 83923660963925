import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Modal from "react-modal"
import { Link } from "react-router-dom"

import sortLeagueTeams from "../functions/sortLeagueTeams"
import getCssClassName from "../functions/getCssClassName"
import { getLastMatchesForLeague } from "../reducers/matches"
import resetTeamPoints from "../functions/resetTeamPoints"
import updateLeagueTeams from "../functions/updateLeagueTeams"
import { goalPercentage } from "../functions/getGoalPercentage"
import PlayerDetail from "./PlayerDetail"
import BidForPlayer from "./BidForPlayer"

function League() {
  const user = useSelector(state => state.user)
  const leagues = useSelector(state => state.leagues)
  const seasons = useSelector(state => state.seasons)
  const teams = useSelector(state => state.teams)
  const players = useSelector(state => state.players)
  const matches = useSelector(state => state.matches)
  const [yearIndex, setYearIndex] = useState(0)
  const [player, setPlayer] = useState(false)
  const getUserLeagueIdByTeamId = seasons => seasons.findIndex(leagues => leagues[yearIndex].teams.find(team => team.teamId === user.teamId))
  const [leagueId, setLeagueId] = useState(getUserLeagueIdByTeamId(seasons))
  const [tableType, setTableType] = useState(false)
  // Don't filter for form etc. on non-current year
  useEffect(() => {
    if (yearIndex > 0) {
      setTableType(false)
    }
  }, [yearIndex])
  const pastWinners = () => {
    const finishedSeasons = [...seasons[leagueId]]
    finishedSeasons.splice(0, 1) // Remove the current season
    const winners = finishedSeasons.map(season => sortLeagueTeams(season.teams)[0])
    return winners.map((winner, i) => {
      return (<tr key={i}>
        <td>{finishedSeasons[i].year}</td>
        <td className={getCssClassName(teams[winner.teamId].shortName + '-' + teams[winner.teamId].logo)}>{teams[winner.teamId].name}</td>
        <td>{winner.won}</td>
        <td>{winner.drawn}</td>
        <td>{winner.lost}</td>
        <td>{winner.for}</td>
        <td>{winner.against}</td>
        <td>{winner.for - winner.against}</td>
        <td>{winner.points}</td>
      </tr>)
    })
  }

  function getTopScorer(limit) {
    // Get a list of all team ids from the chosen season
    const teamIds = seasons[leagueId][yearIndex].teams.map(team => team.teamId)
    // Filter all players for teamId in teamIds
    const playersInThisLeague = players.filter(player => teamIds.includes(player.teamId))
    // Order playersInThisLeague by goals
    return playersInThisLeague.filter(player => player.goals > 0).sort((a, b) => b.goals - a.goals).slice(0, limit)
  }
  const topScorer = getTopScorer(10)

  const calculateStandingFromMatches = (leagueTeams, matches) => {
    resetTeamPoints(leagueTeams)
    matches.forEach(match => {
      let home = leagueTeams.find(leagueTeam => leagueTeam.teamId === match.homeId)
      let away = leagueTeams.find(leagueTeam => leagueTeam.teamId === match.awayId)
      updateLeagueTeams(home, away, match.homeScore, match.awayScore)
    })
  }

  const LeagueTable = () => {
    const league = leagues[leagueId]
    const season = seasons[leagueId][yearIndex]
    let leagueTeams = [...season.teams]

    switch (tableType) {
      case 'form':
        calculateStandingFromMatches(leagueTeams, getLastMatchesForLeague(leagueId, Math.min(5, leagueTeams[0].played), matches))
        break
      case 'full':
      default:
        break
    }

    const sortedLeague = sortLeagueTeams(leagueTeams)

    return (
      <React.Fragment>
        {
          player &&
          <Modal ariaHideApp={false} isOpen={player.id >= 0}>
            {<PlayerDetail player={player} />}
            {<BidForPlayer player={player} setPlayer={setPlayer} />}
            <button onClick={() => setPlayer(false)}>Close</button>
          </Modal>
        }
        <table className={league.id > 0 ? "table--league lower-division" : "table--league"}>
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th><abbr title="Matches Played">PL</abbr></th>
              <th><abbr title="Matches Won">W</abbr></th>
              <th><abbr title="Matches Drawn">D</abbr></th>
              <th><abbr title="Matches Lost">L</abbr></th>
              <th><abbr title="Goals For">F</abbr></th>
              <th><abbr title="Goals Against">A</abbr></th>
              <th><abbr title="Goal Difference">GD</abbr></th>
              <th><abbr title="Unbeaten Run">UR</abbr></th>
              <th><abbr title="Points">P</abbr></th>
            </tr>
          </thead>
          <tbody>
            { // For past season's winners, add &#9733; to the row they appear in
              sortedLeague.map((leagueTeam, i) =>
                <tr key={league.id + "-" + leagueTeam.teamId}>
                  <td>{i + 1}</td>
                  <td className={getCssClassName(teams[leagueTeam.teamId].shortName + '-' + teams[leagueTeam.teamId].logo)}>
                    <Link to={'/team/' + encodeURI(teams[leagueTeam.teamId].shortName)}>
                      <span className="h-s">{teams[leagueTeam.teamId].name}</span>
                      <span className="h-l">{teams[leagueTeam.teamId].shortName}</span>
                    </Link>
                  </td>
                  <td>{leagueTeam.played}</td>
                  <td>{leagueTeam.won}</td>
                  <td>{leagueTeam.drawn}</td>
                  <td>{leagueTeam.lost}</td>
                  <td>{leagueTeam.for}</td>
                  <td>{leagueTeam.against}</td>
                  <td>{leagueTeam.for - leagueTeam.against}</td>
                  <td>{teams[leagueTeam.teamId].unbeatenRun}</td>
                  <td>{leagueTeam.points}</td>
                </tr>
              )
            }
          </tbody>
        </table>
        {seasons[leagueId].length > 1 &&
          <>
            <h3>Past Winners</h3>
            <table className="table--">
              <thead>
                <tr>
                  <th>Year</th>
                  <th>Winners</th>
                  <th><abbr title="Matches Won">W</abbr></th>
                  <th><abbr title="Matches Drawn">D</abbr></th>
                  <th><abbr title="Matches Lost">L</abbr></th>
                  <th><abbr title="Goals For">F</abbr></th>
                  <th><abbr title="Goals Against">A</abbr></th>
                  <th><abbr title="Goal Difference">GD</abbr></th>
                  <th><abbr title="Points">P</abbr></th>
                </tr>
              </thead>
              <tbody>
                {pastWinners()}
              </tbody>
            </table>
          </>
        }
      </React.Fragment>
    )
  }
  // case 37: // left
  // case 38: // up
  // case 39: // right
  // case 40: // down

  return (
    <div className="p-1 s:p-2 m:p-3 l:p-4">
      <nav className="previous-next">
        {yearIndex > 0 &&
          <button className="previous" onClick={() => setYearIndex(yearIndex - 1)}>&larr; {seasons[0][yearIndex - 1].year}</button>
        }
        {seasons[0][yearIndex + 1] !== undefined &&
          <button className="next" onClick={() => setYearIndex(yearIndex + 1)}>&rarr; {seasons[0][yearIndex + 1].year}</button>
        }
      </nav>

      <h1 className="mb-4 text-2xl font-bold font-sans tracking-widest text-center">{seasons[0][yearIndex].year}</h1>
      <div className="card">
        <div className="center">
          <select name="" id="" onChange={e => { setLeagueId(parseInt(e.target.value)) }} value={leagueId}>
            {leagues.map((league, i) => <option key={i} value={league.id}>{league.name}</option>)}
          </select>
          {yearIndex === 0 &&
            <select name="" id="" onChange={e => { setTableType(e.target.value) }}>
              <option key="full" value="full">Full</option>
              <option key="form" value="form">Form</option>
            </select>
          }
        </div>
        {LeagueTable()}
      </div>
      <h3>Top Scorers</h3>
      <div className="card">

        <table className="table--">
          <caption>Top Scorers</caption>
          <thead>
            <tr>
              <th>Team</th>
              <th>Player</th>
              <th>Apps</th>
              <th>Gls</th>
              <th>GPG</th>
            </tr>
          </thead>
          <tbody>
            {topScorer.map((player, i) => {
              return (
                <tr key={i}>
                  <td className={getCssClassName(teams[player.teamId].shortName + '-' + teams[player.teamId].logo)}>{teams[player.teamId].shortName}</td>
                  <td className="onClick" onClick={() => setPlayer(player)}>{`${player.firstName} ${player.lastName}`}</td>
                  <td>{player.appearances}</td>
                  <td>{player.goals}</td>
                  <td>{goalPercentage(player.goals, player.appearances)}%</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default League;
