import { serverUpdate, gameNotFound, serverConnecting, leaveGameLocal } from "./actions"
import store from './store';
import { url, wsUrl, isDev } from './config'
import './websocket'

let sharedWorker

const dispatch = (action) => store.dispatch(action)
const onMessage = (data) => {
  switch (data.type) {
    case 'gameNotFound':
      dispatch(gameNotFound())
      break
    case 'connecting':
      dispatch(serverConnecting())
      break
    case 'connected':
      // TODO: Nothing? Connected
      break
    case 'leaveGame':
       dispatch(leaveGameLocal())
       break
    case 'debug':
      console.log(data);
      break
    default:
      notifyIfWaiting(data.user)
      dispatch(serverUpdate(data))
  }
}

if (typeof SharedWorker !== "undefined" && !isDev) {
  // use websocket via shared worker
  sharedWorker = new SharedWorker(process.env.PUBLIC_URL + '/sharedWorker.js')
  sharedWorker.port.onmessage = function({data}) {
    isDev && console.log(data);
    onMessage(data)
  }

  window.addEventListener('beforeunload', () => {
    isDev && console.log(`Sending 'unload' to sharedWorker`);
    sharedWorker.port.postMessage({
      action: 'unload',
      value: null,
    });
    sharedWorker.port.close();
  });
}

const notifyIfWaiting = (user)  => {
  if (!store.getState().user.gameWaiting && user.gameWaiting) {
    //navigator.serviceWorker.ready.then(registration => {
      const notificationOptions = {
        body: 'The game is waiting on you!',
        icon: '/favicon.ico',
        //tag: 'simple-push-demo-notification',
        data: { url }
      };
      if (Notification.permission === 'granted') new Notification('Unbeaten Run', notificationOptions) // TODO maybe use game name / id in future?
    //}) // registration.showNotification
  }
}

const workerSend = (action, payload = {}, noStore = false, user) => {
  if (!noStore) {
    user = store.getState().user
  }

  if (typeof SharedWorker !== "undefined" && !isDev) {
    // use websocket via shared worker
    isDev && console.log('Sending action to port', action, payload);
    sharedWorker.port.postMessage({action: 'send', value: {wsUrl, action, payload, noStore, user}});
  } else {
    // use websocket directly
    isDev && console.log('Sending action to websocket', action, payload);
    window.ws.send(wsUrl, onMessage, action, payload, noStore, user);
  }
}

export default workerSend
