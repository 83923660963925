import teamsData from "../data/teams"
import { firstName, lastName } from "../data/names"
import createRandomName from "../functions/createRandomName"
import marketValue from "../functions/marketValue"
import { capStat } from "../functions/players"

const randomPosition = () => {
  const possiblePositions = ["Goalkeeper", "Defender", "Midfield", "Forward", "Defender", "Midfield", "Forward", "Defender", "Midfield", "Forward", "Defender", "Midfield", "Forward"]
  return possiblePositions[Math.floor(Math.random() * 13)]
}

export const newPlayer = (id, teamId, gameYears, position) => {
  // 10 to 90 expected
  const statureModifier = teamId > -1 ? teamsData[teamId].stature / 3 : 20

  const player = {
    id: id,
    isRetiring: false,
    teamId: teamId,
    borrowingTeamId: null,
    contractExpiry: (teamId === -1) ? 0 : Math.floor(Math.random() * 3 + 1),
    loanExpiry: null,
    firstName: createRandomName(firstName),
    lastName: createRandomName(lastName),
    position: position ? position : randomPosition(),
    age: Math.round(Math.random() * 20) + 15,
    form: 50,
    goals: 0,
    appearances: 0,
    rating: {
      technical: capStat(statureModifier + Math.floor(Math.random() * 30) + 30, 10, 100), // youth player's technicals exceed mental & physical attributes
      mental: capStat(statureModifier + Math.floor(Math.random() * 30) + ((gameYears === 0) ? 30 : 0), 10, 100),
      physical: capStat(statureModifier + Math.floor(Math.random() * 30) + ((gameYears === 0) ? 30 : 0), 10, 100),
    },
    condition: 50,
    naturalFitness: Math.floor(Math.random() * 80) + 20,
  }

  player.wage = marketValue(player, gameYears)
  player.transferListed = (teamId === -1 || player.rating.technical < 40)
  player.loanListed = (teamId === -1 || player.rating.mental < 40)
  player.isSenior = player.age > 20
  return player
}

let players = []
// Create free agents
for (let j = 0; j < 20; j++) {
  players.push(newPlayer(players.length, -1, 0))
}
// Create players in teams
teamsData.forEach((team, i) => {
   // Total number (20) must match the one used in playerHistory reducer's initialisation
  players.push(newPlayer(players.length, i, 0, "Goalkeeper"))
  for (let j = 0; j < 4; j++) players.push(newPlayer(players.length, i, 0, "Defender"))
  for (let j = 0; j < 4; j++) players.push(newPlayer(players.length, i, 0, "Midfield"))
  for (let j = 0; j < 2; j++) players.push(newPlayer(players.length, i, 0, "Forward"))
  players.push(newPlayer(players.length, i, 0, "Defender"))
  players.push(newPlayer(players.length, i, 0, "Midfield"))
  players.push(newPlayer(players.length, i, 0, "Forward"))
  for (let j = 0; j < 6; j++) { // TODO: Seed number (16) must match the one used in playerHistory reducer's initialisation
    players.push(newPlayer(players.length, i, 0))
  }
})

export default players
