import React, { useState } from "react"
import Modal from "react-modal"
import getCssClassName from "../functions/getCssClassName"
import PlayerDetail from "./PlayerDetail"
import BidForPlayer from "./BidForPlayer"

const didScore = (playerId, goalScorers) => {
  let scorer = goalScorers.find(goalScorer => goalScorer.playerId === playerId)
  return (scorer !== undefined) ? "⚽ " + scorer.goals : ""
}

export default function MatchResultDetail(props) {
  const match = props.match
  const players = props.players
  const homeTeam = props.homeTeam
  const awayTeam = props.awayTeam

  const [player, setPlayer] = useState(false)

  const getPitch = team => {
    return (
      <div className={"formation--" + team.formation + " bg-green-500"}>
        {new Array(11).fill().map((e, i) => <span key={i} className={getCssClassName(team.shortName) + "--primary dot-" + (i + 1) + " flex justify-center items-center h-8 w-8 transition-all text-white rounded-full z-20 leading-none"}>{i + 1}</span>)}
        <div className="six-yard"></div>
        <div className="penalty-area bg-green-500"></div>
        <div className="penalty-arc"></div>
        <div className="center-circle"></div>
      </div>
    )
  }

  return (
    <React.Fragment>
      {player &&
        <Modal ariaHideApp={false} isOpen={player.id >= 0}>
          {<PlayerDetail player={player} />}
          {<BidForPlayer player={player} setPlayer={setPlayer} />}
          <button onClick={() => setPlayer(false)}>Close</button>
        </Modal>
      }
      <header>
        <h1 className="mb-4 text-2xl font-bold font-sans tracking-widest text-center">
          <span className={match.homeScore > match.awayScore ? 'winning-team' : 'non-winning-team'}>
            {homeTeam.name} {match.homeScore}
          </span>{" "}
          -{" "}
          <span className={match.awayScore > match.homeScore ? 'winning-team' : 'non-winning-team'}>
            {match.awayScore} {awayTeam.name}
          </span>
        </h1>
      </header>
      <div className="grid grid-cols-2">
        {getPitch(homeTeam)}
        <table className="table--match-result card">
          <caption>{homeTeam.name}</caption>
          <thead>
            <tr>
              <th>No.</th>
              <th>Name</th>
              <th>Position</th>
              <th>Goals</th>
            </tr>
          </thead>
          <tbody>
            {match.homePlayerIds.map(playerId => {
              let player = players[playerId]
              return (
                <tr key={player.id}>
                  <td>{player.shirtNumber}</td>
                  <td className="onClick" onClick={() => setPlayer(players[playerId])}>{`${players[playerId].firstName} ${players[playerId].lastName}`}</td>
                  <td>{player.position}</td>
                  <td>{didScore(player.id, match.homeScorers)}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <div className="grid grid-cols-2">
        {getPitch(awayTeam)}
        <table className="table--match-result card">
          <caption>{awayTeam.name}</caption>
          <thead>
            <tr>
              <th>No.</th>
              <th>Name</th>
              <th>Position</th>
              <th>Goals</th>
            </tr>
          </thead>
          <tbody>
            {match.awayPlayerIds.map(playerId => {
              let player = players[playerId]
              return (
                <tr key={player.id}>
                  <td>{player.shirtNumber}</td>
                  <td className="onClick" onClick={() => setPlayer(players[playerId])}>{`${players[playerId].firstName} ${players[playerId].lastName}`}</td>
                  <td>{player.position}</td>
                  <td>{didScore(player.id, match.awayScorers)}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  )
}
