import React, { useState } from "react"
import getCssClassName from "../functions/getCssClassName"
import abbreviatePosition from "../functions/abbreviatePosition"
import Modal from "react-modal"
import { bidRejected, bidAccepted, addNews as addNewsLocal } from "../actions"
import { makeNews } from "../functions/addNews"
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"
import PlayerDetail from "./PlayerDetail"
import BidForPlayer from "./BidForPlayer"
import { Link } from "react-router-dom"
import workerSend from "../appWorker"

function Bids() {
  const dispatch = useDispatch()
  const teams = useSelector(state => state.teams)
  const players = useSelector(state => state.players)
  const user = useSelector(state => state.user)
  const market = useSelector(state => state.market)
  const areBids = market.bids.filter(bid => bid.buyingTeamId === user.teamId || bid.sellingTeamId === user.teamId)
  let [bid, setBid] = useState(false)
  const [viewPlayer, setViewPlayer] = useState(false)
  const [bidsMode] = useState('incoming')

  const rejectBid = () => {
    if (market.isOpen) {
      if (user.isLocalGame) {
        dispatch(bidRejected(bid))
        dispatch(addNewsLocal([makeNews(user.currentTime, user.teamId, `${teams[user.teamId].shortName} reject ${"£" + bid.fee} offer for ${players[bid.playerId].firstName} ${players[bid.playerId].lastName}`, "bids", "transfer")]))
      } else {
        workerSend("rejectBid", { bid })
      }
    }
  }

  const acceptBid = () => {
    if (market.isOpen) {
      if (user.isLocalGame) {
        dispatch(bidAccepted(bid))
        dispatch(addNewsLocal([makeNews(user.currentTime, user.teamId, `${teams[user.teamId].shortName} accept ${"£" + bid.fee} offer for ${players[bid.playerId].firstName} ${players[bid.playerId].lastName}`, "bids", "transfer")]))
      } else {
        workerSend("acceptBid", { bid })
      }
    }
  }

  const getIncomingBids = (bids, teams) => {
    return bids.filter(bid => bid.sellingTeamId === user.teamId)
      .map((bid, i) => {
        let buyingTeam = teams[bid.buyingTeamId]
        let player = players[bid.playerId]
        return (<tr key={i}>
          <td>{moment(bid.dateTime).format("MMM D, 'YY")}</td>
          <td className="onClick" onClick={() => setViewPlayer(player)}>{`${player.firstName} ${player.lastName}`}</td>
          <td className={getCssClassName(buyingTeam.shortName + '-' + buyingTeam.logo)}><Link to={`/team/${buyingTeam.shortName}`}>{buyingTeam.shortName}</Link></td>
          <td>{abbreviatePosition(player.position)}</td>
          <td>£{bid.fee.toLocaleString()}</td>
          {
            bid.status === ""
              ? <td>{market.isOpen ? <button onClick={() => setBid(bid)}>Respond</button> : <span>Window closed</span>}</td>
              : <td>{bid.status === "accepted" ? <span className="green">✔</span> : <span className="red">✘</span>}</td>
          }
        </tr>)
      })
  }

  const getOutgoingBids = (bids, teams) => {
    return bids
      .filter(bid => bid.buyingTeamId === user.teamId)
      .map((bid, i) => {
        let sellingTeam = teams[bid.sellingTeamId]
        let player = players[bid.playerId]
        return (<tr key={i}>
          <td>{moment(bid.dateTime).format("MMM D, 'YY")}</td>
          <td className="onClick" onClick={() => setViewPlayer(player)}>{`${player.firstName} ${player.lastName}`}</td>
          <td className={getCssClassName(sellingTeam.shortName + '-' + sellingTeam.logo)}><Link to={`/team/${sellingTeam.shortName}`}>{sellingTeam.shortName}</Link></td>
          <td>{abbreviatePosition(player.position)}</td>
          <td>{bid.fee !== undefined ? bid.fee.toLocaleString() : "Loan"}</td>
          <td>{bid.status && (bid.status === "accepted" ? <span className="green">✔</span> : <span className="red">✘</span>)}</td>
        </tr>)
      })
  }

  return (
    <div className="p-1 s:p-2 m:p-3 l:p-4">
      {viewPlayer &&
        <Modal ariaHideApp={false} isOpen={viewPlayer.id >= 0}>
          {<PlayerDetail player={viewPlayer} />}
          {<BidForPlayer player={viewPlayer} setPlayer={setViewPlayer} />}
          <button onClick={() => setViewPlayer(false)}>Close</button>
        </Modal>
      }
      {bid &&
        <Modal ariaHideApp={false} isOpen={true}>
          <h3>Respond to Bid</h3>
          <PlayerDetail player={players[bid.playerId]} />
          <button className="f-r" onClick={() => setBid(false)}>Close</button>
          <ul className="list-inline">
            <li>£{bid.fee.toLocaleString()} bid from {<Link to={`/team/${teams[bid.buyingTeamId].shortName}`}>{teams[bid.buyingTeamId].shortName}</Link>}</li>
            <li>Bid expires: {moment(bid.expires).format("MMM D, 'YY")}</li>
            <li><button onClick={() => { acceptBid(bid); setBid(false); }} className="cta--accept">Accept bid</button></li>
            <li><button onClick={() => { rejectBid(bid); setBid(false) }} className="cta--reject">Reject bid</button></li>
          </ul>
        </Modal>
      }
      <h1 className="mb-4 text-2xl font-bold font-sans tracking-widest text-center">Bids</h1>
      {
        areBids.length > 0 &&
        (
          <table className="table--ghost card">
            <thead>
              <tr>
                <th>Date</th>
                <th>Player</th>
                <th>Club</th>
                <th><abbr title="Position">POS</abbr></th>
                <th>Fee</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {bidsMode === 'incoming' && getIncomingBids(market.bids, teams)}
              {bidsMode === 'outgoing' && getOutgoingBids(market.bids, teams)}
            </tbody>
          </table>
        )
      }
    </div>
  )
}

export default Bids
