const sortPlayers = (players, criteria, toggle) => {
  if (players.length === 0) return players;
  if ( typeof(players[0][criteria] ) === "string" ){
		players.sort((a,b) => {
			if (a[criteria] < b[criteria])
				return -1
			else if (b[criteria] > a[criteria])
				return 1
			else
				return 0
		})
  } else {
			players.sort((a,b) => a[criteria] - b[criteria])
  }
  return toggle ? players.reverse() : players
}
export default sortPlayers
