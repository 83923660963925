const winLoseDraw = (teamId, homeId, homeScore, awayScore) => {
  if (homeScore > awayScore) {
    return (teamId === homeId) ? "win" : "lose"
  } else if (awayScore > homeScore) {
    return (teamId !== homeId) ? "win" : "lose"
  }
  return "draw";
}

export default winLoseDraw
