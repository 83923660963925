import React from "react"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import "./css/app.css"
import "./css/tailwind-out.css"
import { useSelector } from "react-redux"
import Masthead from "./components/Masthead"
import NavHorizontal from './components/NavHorizontal'
import SubNav from './components/SubNav'
import Overview from "./components/Overview"
import MatchResult from "./components/MatchResult"
import SelectTeam from "./components/SelectTeam"
import PlayerContracts from "./components/PlayerContracts"
import Bids from "./components/Bids"
import TransferList from "./components/TransferList"
import Transfers from "./components/Transfers"
import FreeAgents from "./components/FreeAgents"
import Results from "./components/Results"
import Fixtures from "./components/Fixtures"
import League from "./components/League"
import Finances from './components/Finances'
import Inbox from './components/Inbox'
import Team from './components/Team'
import LongList from './components/LongList'
import Cups from './components/Cups'
import Cup from './components/Cup'
import JoinOrCreateGame from './components/JoinOrCreateGame'
import Footer from './components/Footer'
import Ui from './components/Ui'


function App() {
  const user = useSelector(state => state.user)

  return (
    <Router>
      <Masthead />
      <React.Fragment>
        <NavHorizontal />
        <Switch>
          <Route path="/match-result">
            <SubNav type="matches" />
            <MatchResult />
          </Route>
          <Route path="/league">
            <SubNav type="matches" />
            <League />
          </Route>
          <Route path="/matchdetail/:id">
            <SubNav type="matches" />
            <Results />
          </Route>
          <Route path="/results">
            <SubNav type="matches" />
            <Results />
          </Route>
          <Route path="/fixtures">
            <SubNav type="matches" />
            <Fixtures />
          </Route>
          <Route path="/bids">
            <SubNav type="transfers" />
            <Bids />
          </Route>
          <Route path="/transfer-list">
            <SubNav type="transfers" />
            <TransferList />
          </Route>
          <Route path="/transfers">
            <SubNav type="transfers" />
            <Transfers />
          </Route>
          <Route path="/select-team">
            <SelectTeam />
          </Route>
          <Route path="/free-agents">
            <SubNav type="transfers" />
            <FreeAgents />
          </Route>
          <Route path="/finances">
            <SubNav type="money" />
            <Finances />
          </Route>
          <Route path="/player-contracts">
            <SubNav type="money" />
            <PlayerContracts />
          </Route>
          <Route path="/inbox">
            <Inbox />
          </Route>
          <Route path="/long-list">
            <SubNav type="transfers" />
            <LongList />
          </Route>
          <Route path="/cups">
            <SubNav type="matches" />
            <Cups />
          </Route>
          <Route path="/JoinOrCreateGame">
            <JoinOrCreateGame />
          </Route>
          <Route path="/team/:teamName">
            <SubNav type="matches" />
            <Team />
          </Route>
          <Route path="/cup/:cupName">
            <SubNav type="matches" />
            <Cup />
          </Route>
          <Route path="/ui">
            <Ui />
          </Route>
          <Route path="/">
            {user.teamId > -1
              ? <Overview />
              : <JoinOrCreateGame />}
          </Route>
        </Switch>
        <Footer />
      </React.Fragment>
    </Router>
  )
}

export default App
