import marketValue from '../functions/marketValue';
import { newBidNews } from "../functions/market"
import moment from "moment"

const aiBids = (seasonCount, teams, players, currentTime, users) => {
  const user = users[0]
  const news = []
  const newBids = []

  for (let i = 0; i < 3; i++) {
    const randomNo = limit => Math.floor(Math.random() * limit)
    const allTeams = [...teams]
    const aiTeams = teams.filter(team => !team.userId)

    const randomBuyer = aiTeams[randomNo(aiTeams.length)]
    allTeams.splice(randomBuyer.id, 1)

    const randomSeller = allTeams[randomNo(allTeams.length)]
    const randomPlayerId = randomSeller.squadOrder[randomNo(randomSeller.squadOrder.length)]
    const player = players[randomPlayerId]

    newBidNews(news, teams, users, randomSeller, currentTime, randomBuyer, player)

    newBids.push({
      playerId: randomPlayerId,
      buyingTeamId: randomBuyer.id,
      sellingTeamId: randomSeller.id,
      fee: Math.round(marketValue(player, seasonCount)),
      dateTime: currentTime,
      type: "transfer",
      status: "",
      expires: moment(user.currentTime).add(5, 'd')
    })
  }

  return { news, newBids }
}

const processBids = (bids, teams, players, seasonCount, user) => {
  // If we have any bids
  // Process each bid and either complete the Transfer
  // Or reject the bid

  let bidsToAccept = []
  let bidsToReject = []

  // For now this is just based on luck. Higher bids for the same player make no difference.
  if (bids.length > 0) {
    let acceptedPlayerIds = []
    let teamSquadCounts = []
    teams.forEach(team => teamSquadCounts.push(team.squadOrder.length))

    bids.forEach(bid => {
      if (bid.status === "" && !teams[bid.sellingTeamId].userId) {
        const luck = Math.round(Math.random())
        if (
          luck
          && !acceptedPlayerIds.includes(bid.playerId)
          && teamSquadCounts[bid.sellingTeamId] > 11
          && bid.fee >= marketValue(players[bid.playerId], seasonCount)
        ){
            bidsToAccept.push(bid)
            acceptedPlayerIds.push(bid.playerId)
            teamSquadCounts[bid.sellingTeamId]--
            teamSquadCounts[bid.buyingTeamId]++
        } else {
            bidsToReject.push(bid)
        }
      } else if ( // the user's player is bid on, and the expiration of the bid has happened
          bid.status === ""
          && teams[bid.sellingTeamId].userId
          && moment(user.currentTime).isAfter(moment(bid.expires))
      ){
        bidsToReject.push(bid)
      }
    })
  }
  return { bidsToAccept, bidsToReject }
}

export {
  processBids,
  aiBids
}
