import { addNews, makeNews } from "../functions/addNews"

const getBidIndex = (bids, bid) => {
  return bids.findIndex(i => i.playerId === bid.playerId && i.buyingTeamId === bid.buyingTeamId && i.sellingTeamId === bid.sellingTeamId && i.fee === bid.fee && i.dateTime === bid.dateTime)
}

const newBidNews = (news, teams, users, sellingTeam, currentTime, buyingTeam, player) => {
  // If the selling team has a user, add news for them
  if (sellingTeam.userId) {
    addNews(news,
      sellingTeam.userId,
      currentTime,
      sellingTeam.id,
      `${buyingTeam.shortName} bid for ${player.firstName} ${player.lastName}`,
      "bids",
      "transfer"
    )
  }

  // If the player is in a user's long list, add news for that user
  teams.filter(team => team.userId && team.id !== buyingTeam.id && team.id !== sellingTeam.id)
  .forEach(team => {
    const user = users.find(user => user.id === team.userId)
    if (user && user.longList.includes(player.id)) {
      addNews(news,
        team.userId,
        currentTime,
        team.id,
        `Long-listed player, ${player.firstName} ${player.lastName} has had a bid made by ${buyingTeam.shortName}`,
        "long-list",
        "transfer"
      )
    }
  })
}

const newBidResponseNews = (news, teams, users, sellingTeam, currentTime, buyingTeam, player, fee, response) => {
  // If the buying team has a user, add news for them
  if (buyingTeam.userId) {
    addNews(news,
      buyingTeam.userId,
      currentTime,
      buyingTeam.id,
      `${sellingTeam.shortName} ${response} ${"£" + fee} offer from ${buyingTeam.shortName} for ${player.firstName} ${player.lastName}`,
      "bids",
      "transfer"
    )
  }

  // If the player is in another user's long list, add news for that user
  teams.filter(team => team.userId && team.id !== buyingTeam.id && team.id !== sellingTeam.id)
  .forEach(team => {
    const user = users.find(user => user.id === team.userId)
    if (user && user.longList.includes(player.id)) {
      addNews(news,
        team.userId,
        currentTime,
        team.id,
        `${sellingTeam.shortName} ${response} ${"£" + fee} offer from ${buyingTeam.shortName} for your long-listed player ${player.firstName} ${player.lastName}`,
        "long-list",
        "transfer"
      )
    }
  })
}

const shouldMarketBeOpen = (currentTime) => {
  const currentDate = new Date(currentTime)
  return [0,6,7,8].includes(currentDate.getMonth())
}

const makeMarketOpenNews = (currentTime, teamId, shouldBeOpen) => {
  return makeNews(currentTime, teamId, `The transfer window is now ${shouldBeOpen ? 'open' : 'closed'}!`, shouldBeOpen ? 'transfer-list' : 'transfers', "transfer")
}

export {
  getBidIndex,
  newBidNews,
  newBidResponseNews,
  shouldMarketBeOpen,
  makeMarketOpenNews,
}
