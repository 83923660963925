const sortLeagueTeams = (teams) => {
  const sortedTeams = [...teams]
  sortedTeams.sort((a,b) => {
    if (a.points > b.points) return -1
    else if (a.points < b.points) return 1
    else if (a.played < b.played) return -1
    else if (a.played > b.played) return 1
    else if (a.for-a.against > b.for-b.against) return -1
    else if (a.for-a.against < b.for-b.against) return 1
    else if (a.for > b.for) return -1
    else if (a.for < b.for) return 1
    else if (a.against > b.against) return -1
    else if (a.against < b.against) return 1
    else return 0
  })
  return sortedTeams
}

export default sortLeagueTeams;
