const leagues = [
  {
    id: 0,
    name: "First Division",
    continent: "Brittanic",
    tier: 1,
    relegationId: 1,
    topPrizeMoney: 1000000,
  },
  {
    id: 1,
    name: "Second Division",
    continent: "Brittanic",
    tier: 2,
    promotionId: 0,
    topPrizeMoney: 500000,
  }
]

export default leagues;
