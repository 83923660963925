import leaguesData from "../data/leagues"

const leaguesReducer = (state = leaguesData, action) => {
  const newData = action.leagues

  switch(action.type) {
    case "newGame":
      return leaguesData
    case 'gameJoined':
      return action.leagues
    case 'incrementalServerData':
      return (newData !== undefined) ? newData : state
    case 'fullServerData':
      return (newData !== undefined) ? newData : []
    default:
        return state;
  }
}

export default leaguesReducer;
