import { playMatch } from "./playMatch"
import { getPlayersFromSquadOrder } from "../../functions/players"
import { addNews } from "../addNews"

const playMatches = (playersHistory, teams, players, fixtures, boards) => {
  const playerHistories = []
  const results = []
  const news = []
  const usersPlayed = []

  fixtures.forEach(fixture => {
    let home = teams[fixture.homeId]
    let away = teams[fixture.awayId]
    // Reduce the attendance for every percent less than complete board confidence
    let boardConfidence = boards[fixture.homeId].confidence
    let capacity = teams[fixture.homeId].stadium.capacity
    let attendance = (capacity / 100) * boardConfidence
    // Starting 11 and bench 5
    let homePlayers = getPlayersFromSquadOrder(home.squadOrder, players)
    let awayPlayers = getPlayersFromSquadOrder(away.squadOrder, players)
    let result = {}
    let [homeScore, awayScore, homeScorers, awayScorers, homeSalary, awaySalary] = playMatch(home, homePlayers, away, awayPlayers)

    result.fixture = fixture
    result.home = home
    result.away = away
    result.attendance = Math.round(attendance)
    result.homePlayerIds = home.squadOrder.slice(0, 11) // Update to include subs brought on
    result.awayPlayerIds = away.squadOrder.slice(0, 11) // Update to include subs brought on
    result.homeScore = homeScore
    result.awayScore = awayScore
    result.homeScorers = homeScorers
    result.awayScorers = awayScorers
    result.homeSalary = homeSalary
    result.awaySalary = awaySalary

    // Record appearance (and goals) for all 22 players
    const playersInvolved = [[...home.squadOrder.slice(0, 11)], [...away.squadOrder.slice(0, 11)]]
    const goalScorers = [...homeScorers, ...awayScorers]
    const numberOfGoalsForPlayer = (goalScorers, playerId) => {
      let scorer = goalScorers.find(goalScorer => goalScorer.playerId === playerId)
      return scorer ? scorer.goals : 0
    }

    playersInvolved.forEach((team, i) => {
      let teamId = (i === 0) ? fixture.homeId : fixture.awayId
      team.forEach(playerId => {
        const goals = numberOfGoalsForPlayer(goalScorers, playerId)
        let competitionId = fixture.leagueId >= 0 ? fixture.leagueId : fixture.cupId
        let competitionType = fixture.leagueId >= 0 ? 'league' : 'cup'
        let competitionYear = fixture.competitionYear
        playerHistories[playerId] = updatePlayerHistory(playersHistory[playerId], competitionId, competitionType, competitionYear, goals, teamId)
      })
    })

    results.push(result)

    if (home.userId) {
      usersPlayed.push(home.userId)
      addNews(news, home.userId, fixture.kickOff, home.id, `${result.home.shortName} ${result.homeScore} — ${result.awayScore} ${result.away.shortName}`, `matchdetail/${fixture.id}`, 'match')
    }
    if (away.userId) {
      usersPlayed.push(away.userId)
      addNews(news, away.userId, fixture.kickOff, away.id, `${result.home.shortName} ${result.homeScore} — ${result.awayScore} ${result.away.shortName}`, `matchdetail/${fixture.id}`, 'match')
    }
  })

  return { usersPlayed, results, playerHistories, news }
}


const updatePlayerHistory = (playerHistory, competitionId, competitionType, competitionYear, goals, teamId) => {
  function findCompetitionIndex (competitions, teamId, competitionId, competitionType, competitionYear) {
    return competitions.findIndex(competition =>
      (competition.teamId === teamId
      && competition.competitionId === competitionId
      && competition.competitionType === competitionType
      && competition.competitionYear === competitionYear)
    )
  }

  let competitionHistory, newPlayerHistory
  if (playerHistory) {
    newPlayerHistory = [...playerHistory]
  } else {
    newPlayerHistory = []
  }
  let competitionIndex = findCompetitionIndex(newPlayerHistory, teamId, competitionId, competitionType, competitionYear)
  if (competitionIndex > -1) {
    competitionHistory = {...newPlayerHistory[competitionIndex]}
    newPlayerHistory[competitionIndex] = competitionHistory
  } else {
    competitionHistory = { teamId, competitionId, competitionType, competitionYear, appearances: 0, goals : 0 }
    newPlayerHistory.unshift(competitionHistory)
  }

  competitionHistory.appearances += 1
  if (goals > 0) competitionHistory.goals += goals
  return newPlayerHistory
}

export default playMatches;
