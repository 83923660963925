const cups = [
  {
    id: 0,
    name: "Hero's Cup",
    continents: ["Brittanic"],
    tiers: [1],
    topPrizeMoney: 1000000,
    nextYear: 2023,
    yearIntervals: 1,
    topXFromLeague: 16,
    dayOffset: 7*3,
    daysBetweenRounds: 7*3,
    daysFromSaturday: 3
  },
  {
    id: 1,
    name: "Challenge Cup",
    continents: ["Brittanic"],
    tiers: [1,2],
    topPrizeMoney: 500000,
    nextYear: 2025,
    yearIntervals: 1,
    topXFromLeague: 8,
    dayOffset: 7*18,
    daysBetweenRounds: 7*3,
    daysFromSaturday: 3
  },
  {
    id: 2,
    name: "Praetorian Cup",
    continents: ["Brittanic"],
    tiers: [2],
    topPrizeMoney: 10000,
    nextYear: 2025,
    yearIntervals: 1,
    topXFromLeague: 16,
    dayOffset: 7*3,
    daysBetweenRounds: 7*3,
    daysFromSaturday: 3
  },
]

export default cups;
