// Seasons top level index == leagueId
const getTeamLeagueId = (seasons, teamId) => {
  return seasons.findIndex(league =>
    league[0].teams.length > 0
      ? league[0].teams.findIndex(team => team.teamId === teamId) > -1
      : false
  )
}

const findPlayerPos = (team, playerId) => {
  return team.squadOrder.findIndex(id => playerId === id)
}

const getTeamIdByShortName = (teams, shortName) => {
  return teams.findIndex(team => team.shortName.toLowerCase() === shortName.toLowerCase())
}

export {
  getTeamLeagueId,
  findPlayerPos,
  getTeamIdByShortName,
}
