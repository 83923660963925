import cupYearsData from "../data/cupYears"
import { findTeamPos } from "../functions/seasons"
import { updateCupTeams } from "../functions/cupYears"

const cupYearsReducer = (state = cupYearsData, action) => {
  let thisCupYears
  const newData = action.cupYears

  switch(action.type){
    case 'newGame':
      return cupYearsData
    case 'gameJoined':
      return action.cupYears
    case 'incrementalServerData':
      return (newData !== undefined) ? newData : state
    case 'fullServerData':
      return (newData !== undefined) ? newData : []
    case 'matchPlayed':
      const result = action.result
      if (result.fixture.cupId !== undefined) {
        const cupYears = [...state]
        const thisCupYears = [...cupYears[result.fixture.cupId]]
        cupYears[result.fixture.cupId] = thisCupYears
        thisCupYears[0] = {...thisCupYears[0]}
        const thisCupYearTeams = [...thisCupYears[0].teams]
        thisCupYears[0].teams = thisCupYearTeams

        const homeTeamPos = findTeamPos(thisCupYearTeams, result.fixture.homeId)
        const awayTeamPos = findTeamPos(thisCupYearTeams, result.fixture.awayId)

        thisCupYearTeams[homeTeamPos] = {...thisCupYearTeams[homeTeamPos]}
        thisCupYearTeams[awayTeamPos] = {...thisCupYearTeams[awayTeamPos]}
        updateCupTeams(thisCupYearTeams[homeTeamPos], thisCupYearTeams[awayTeamPos], result.homeScore, result.awayScore)
        return cupYears
      }
      return state
    case 'addTeamToCupYear' :
      let cupYears = [...state]
      let year = action.year
      thisCupYears = [...cupYears[action.cupId]]
      if (thisCupYears[0].year !== year) {
        thisCupYears.unshift({ year, teams: []})
      } else {
        thisCupYears[0] = {...thisCupYears[0]}
        thisCupYears[0].teams = [...thisCupYears[0].teams]
      }
      thisCupYears[0].teams.push({ teamId: action.teamId, knockedOut: false })
      cupYears[action.cupId] = thisCupYears
      return cupYears

    default:
        return state;
  }
}

export default cupYearsReducer;
