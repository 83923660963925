import cupsData from "../data/cups"
import getCssClassName from "../functions/getCssClassName"

const cupsReducer = (state = cupsData, action) => {
  let cups = [ ...state ]
  const newData = action.cups

  switch(action.type) {
    case "newGame":
      return cupsData
    case 'gameJoined':
      return action.cups
    case 'incrementalServerData':
      return (newData !== undefined) ? newData : state
    case 'fullServerData':
      return (newData !== undefined) ? newData : []
    case 'nextYear':
      cups.forEach((cup, i) => {
        if (cup.nextYear <= action.nextCupYear) {
          cups[i] = { ...cup }
          cups[i].nextYear += cup.yearIntervals
        }
      });
      return cups

    default:
        return state;
  }
}

export default cupsReducer;

export const getCupIdByName = (cups, name) => {
  return cups.findIndex(cup => getCssClassName(cup.name) === getCssClassName(name))
}

export const cupYearHasWinner = ( cupYear ) => cupYear.teams.filter(team => team.knockedOut === false).length === 1

export const getCupHolderId = (cupYears, cupId) => {
  const thisYearHasWinner = cupYearHasWinner(cupYears[cupId][0])
  const firstYear = cupYears[cupId].length === 1
  let returnValue = cupYears[cupId][0].teams.filter(team => team.knockedOut === false)[0].teamId
  if( firstYear ){
    if( !thisYearHasWinner ){
        returnValue = -1
    }
  }
  return returnValue
}
