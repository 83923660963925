import React, { useState } from "react";
import { Link } from "react-router-dom"
import Modal from "react-modal"
import { useSelector, useDispatch } from "react-redux";

import { movePlayerPositionLocal, changeFormationLocal, changeTacticLocal, teamReady } from "../actions";
import abbreviatePosition from "../functions/abbreviatePosition"
import getCssClassName from "../functions/getCssClassName"
import { getNextFixtureForTeam } from "../functions/fixtures"
import { getTeamLeagueId } from "../functions/teams"
import { getPlayerTeamLatestSeasonHistory } from "../reducers/playersHistory"
import PlayerDetail from "./PlayerDetail"
import workerSend from "../appWorker"
import Button from "./Button";
import nextTurn, { isUserMatchDay } from "../localFunctions/nextTurn"

// TODO refactor to update a 'staged' team state (and then the websocket) on a 'submit'
// for tactic, formation and squadOrder (and anything else changed here)
// TODO Ensure websocket update doesn't overwrite these local values except on appInit
// Maybe just put these in hooks and lose them on refresh?

function SelectTeam() {
  const user = useSelector(state => state.user)
  const teams = useSelector(state => state.teams)
  const team = useSelector(state => state.teams[user.teamId])
  const players = useSelector(state => state.players)
  const playersHistory = useSelector(state => state.playersHistory)
  const seasons = useSelector(state => state.seasons)
  const dispatch = useDispatch()
  const formation = team.formation
  const [viewPlayer, setViewPlayer] = useState(false)
  const playersAllowed = [11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1]
  const fixtures = useSelector(state => state.fixtures)
  const nextMatch = getNextFixtureForTeam(
    fixtures.leagues[getTeamLeagueId(seasons, user.teamId)],
    fixtures.cups,
    user.teamId
  )
  let opponentId
  if (nextMatch)
    opponentId = nextMatch.homeId === user.teamId ? nextMatch.awayId : nextMatch.homeId
  const getTeamRatings = team => {
    const showPlayerRatingFor = (criteria, label) => {
      return (
        <li>
          {label}<br />
          <div className="stars" style={{ "--rating": criteria / 20 }} aria-label={label}></div>
        </li>
      )
    }
    let physical = 0, mental = 0, technical = 0, overall = 0
    team.squadOrder.slice(0, 11).forEach(playerId => {
      physical += players[playerId].rating.physical
      mental += players[playerId].rating.mental
      technical += players[playerId].rating.technical
    })
    overall += physical + mental + technical
    return (
      <ul>
        {showPlayerRatingFor(Math.round(physical / 11), 'Physical')}
        {showPlayerRatingFor(Math.round(mental / 11), 'Mental')}
        {showPlayerRatingFor(Math.round(technical / 11), 'Technical')}
        {showPlayerRatingFor(Math.round((overall / 11) / 3), 'Overall')}
      </ul>
    )
  }

  const changeFormation = (formation) => {
    if (user.isLocalGame) {
      dispatch(changeFormationLocal(user.teamId, formation))
    } else {
      workerSend("changeFormation", { formation })
    }
  }

  const changeTactic = (tactic) => {
    if (user.isLocalGame) {
      dispatch(changeTacticLocal(user.teamId, tactic))
    } else {
      workerSend("changeTactic", { tactic })
    }
  }

  const movePlayerPosition = (currentIndex, newIndex) => {
    if (user.isLocalGame) {
      dispatch(movePlayerPositionLocal(team.id, currentIndex, newIndex))
    } else {
      workerSend("movePlayerPosition", { currentIndex, newIndex })
    }
  }

  const setTeamReady = (teamId) => {
    if (user.isLocalGame) {
      dispatch(teamReady(team.id))
    } else {
      workerSend("teamReady", { })
    }
    nextTurn()
  }

  const proceedToMatch = () => {
    return (
      <footer className="p-4 text-center">
    {(user.isLocalGame) ?
      <Link className="btn first"  onClick={() => setTeamReady(team.id) } to="/match-result">Proceed to match</Link>
    :
      <Link className="btn first"  onClick={() => setTeamReady(team.id) }>Proceed to match {user.ready && <span className="rotating">X</span>}</Link>
    }
    </footer>
  )
  }

  return (
    <div className="p-1 s:p-2 m:p-3 l:p-4">
      { isUserMatchDay() && proceedToMatch() }
      {
        viewPlayer &&
        <Modal ariaHideApp={false} isOpen={viewPlayer.id >= 0}>
          {<PlayerDetail player={viewPlayer} />}
          <Button onClickFunction={() => setViewPlayer(false)} label="Close" />
        </Modal>
      }
      <section className="formation-select">
        <div className="grid grid-cols-2">
          <section>
            <div className="grid grid-cols-2">
              <ul>
                <li className={getCssClassName(team.shortName) + '-' + team.logo}>{team.shortName}</li>
                {getTeamRatings(team)}
              </ul>
              {
                opponentId &&
                <ul>
                  <li className={getCssClassName(teams[opponentId].shortName) + '-' + teams[opponentId].logo}>{teams[opponentId].shortName}</li>
                  {getTeamRatings(teams[opponentId])}
                </ul>
              }
            </div>
            <hr className="my-4" />
            <ul className="flex items-center gap-6">
              <li>
                <ul className="flex items-center gap-2">
                  <li className="text-sm">Formation</li>
                  <li>
                    <select className="border border-black px-1" value={team.formation} onChange={e => { changeFormation(e.target.value) }}>
                      <option value="4-4-2">4-4-2</option>
                      <option value="4-5-1">4-5-1</option>
                      <option value="4-3-3">4-3-3</option>
                      <option value="3-4-3">3-4-3</option>
                      <option value="3-5-2">3-5-2</option>
                    </select>
                  </li>
                </ul>
              </li>
              <li>
                <ul className="flex items-center gap-2">
                  <li>Tactic</li>
                  <li>
                    <select className="border border-black px-1" value={team.tactic} onChange={e => { changeTactic(e.target.value) }}>
                      <option value="Defensive">Defensive</option>
                      <option value="Possession">Possession</option>
                      <option value="Attacking">Attacking</option>
                      <option value="Counter-attacking">Counter-Attacking</option>
                    </select>
                  </li>
                </ul>
              </li>
            </ul>
          </section>
          <div className={"formation--" + formation + " bg-green-500"}>
            {playersAllowed.map((e, i) => <span key={i} className={getCssClassName(team.shortName) + "--primary dot-" + e + " flex justify-center items-center h-8 w-8 transition-all text-white rounded-full z-20 leading-none"}>{e}</span>)}
            <div className="six-yard"></div>
            <div className="penalty-area bg-green-500"></div>
            <div className="penalty-arc"></div>
            <div className="center-circle"></div>
          </div>
        </div>
      </section >
      <table className={"table--lineup-" + formation + ' card'}>
        <thead>
          <tr>
            <th>Shirt #</th>
            <th></th>
            <th><abbr title="Position">POS</abbr></th>
            <th>Name</th>
            <th><abbr title="Appearances">APP</abbr></th>
            <th><abbr title="Goals">GLS</abbr></th>
            <th>Wage</th>
            <th><abbr title="Physical, Mental, Technical, & Overall Rating">RAT</abbr></th>
            <th>Condition</th>
            <th>Form</th>
          </tr>
        </thead>
        <tbody>
          {team.squadOrder.map((playerId, i) => {
            let player = players[playerId]
            let playerHistory = getPlayerTeamLatestSeasonHistory(playersHistory, playerId, team.id)
            let appearances = !playerHistory ? 0 : playerHistory.appearances
            let goals = appearances > 0 ? playerHistory.goals : 0
            let { physical, mental, technical } = player.rating
            let ratingOutOf100 = Math.floor((technical + mental + physical) / 3)
            return (
              <tr key={player.id}
                data-rating={ratingOutOf100}
                data-position={player.position.toLowerCase()}
                data-form={Math.round(player.form / 10) * 10}>
                <td className="no-compact">
                  <select className="border border-black p-1" onChange={e => movePlayerPosition(i, parseInt(e.target.value - 1))}>
                    {team.squadOrder.map((ignore, j) => {
                      return j === i
                        ? <option selected>{j + 1}</option>
                        : <option>{j + 1}</option>
                    })}
                  </select>
                </td>
                <td className="c">{player.loanListed && <i className="icon--loan-listed"></i>}{player.transferListed && <i className="icon--transfer-listed"></i>}</td>
                <td className={player.position.toLowerCase()}>{abbreviatePosition(player.position)}</td>
                <td className="onClick" onClick={() => setViewPlayer(player)}>{`${player.firstName} ${player.lastName}`}</td>
                <td>{appearances}</td>
                <td>{goals}</td>
                <td className="accounting">{player.wage.toLocaleString()}</td>
                <td className="highlight">
                  <div className="stars" style={{ "--rating": ratingOutOf100 / 20 }}></div>
                </td>
                <td>{player.condition}</td>
                <td>{player.form}</td>
              </tr>
            )
          }
          )}
        </tbody>
      </table>
    </div >
  );
}

export default SelectTeam
