import React from "react";
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom'
import getCssClassName from "../functions/getCssClassName"
import { getCupHolderId } from "../reducers/cups"

function Cups() {
  const cups = useSelector(state => state.cups)
  const cupYears = useSelector(state => state.cupYears)
  const teams = useSelector(state => state.teams)
  return (
    <div className="p-1 s:p-2 m:p-3 l:p-4">
      <h1 className="mb-4 text-2xl font-bold font-sans tracking-widest text-center">Cup Competitions</h1>
      <div className="overview">
        {cups.map((cup, i) => {
          let holderId = getCupHolderId(cupYears, cup.id)
          return (
            <div className="card c mb1" key={i}>
              <h3><Link to={`/cup/${getCssClassName(cup.name)}`}>{cup.name}</Link></h3>
              {holderId > -1 && <p className={getCssClassName(`${teams[holderId].shortName}-${teams[holderId].logo}-above`)}></p>}
              {holderId > -1 && <h3>{cupYears[cup.id][0].year} winners<br />{teams[holderId].shortName}</h3>}
            </div>
          )
        })}
      </div>
    </div>
  );
}
export default Cups;
