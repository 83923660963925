import randomNo from "../functions/randomNo"
const tactic = ["Defensive", "Possession", "Attacking", "Counter-Attacking"]

const teams = [
  {
    shortName: "Twins",
    logo: "diamond",
    colors: [
      { primary: "0, 100, 72" },
      { secondary: "11, 73, 36" },
      { away: "43, 100, 61" },
      { other: "157, 73, 60" }
    ],
    stadium: {
      name: "Brotherly Street",
      capacity: 49000
    },
    name: 'Terrazzo Twins',
    continent: "Brittanic",
    tier: 1
  },
  {
    shortName: "Cosmos",
    logo: "diamond",
    colors: [
      { primary: "0, 100, 72" },
      { secondary: "11, 73, 36" },
      { away: "43, 100, 61" },
      { other: "157, 73, 60" }
    ],
    stadium: {
      name: "Higher Center",
      capacity: 35000
    },
    name: 'First Cosmonauts',
    continent: "Brittanic",
    tier: 1
  },
  {
    shortName: "Giants",
    logo: "diamond",
    colors: [
      { primary: "0, 100, 72" },
      { secondary: "11, 73, 36" },
      { away: "43, 100, 61" },
      { other: "157, 73, 60" }
    ],
    stadium: {
      name: "The Mountaintop",
      capacity: 95605
    },
    name: 'Stone Giants',
    continent: "Brittanic",
    tier: 1
  },
  {
    shortName: "Loco",
    logo: "diamond",
    colors: [
      { primary: "0, 100, 72" },
      { secondary: "11, 73, 36" },
      { away: "43, 100, 61" },
      { other: "157, 73, 60" }
    ],
    stadium: {
      name: "Station Road",
      capacity: 54011
    },
    name: 'Locomotive',
    continent: "Brittanic",
    tier: 1
  },
  {
    shortName: "Eagle",
    logo: "diamond",
    colors: [
      { primary: "0, 100, 72" },
      { secondary: "11, 73, 36" },
      { away: "43, 100, 61" },
      { other: "157, 73, 60" }
    ],
    stadium: {
      name: "The Collosus",
      capacity: 60400
    },
    name: 'The Eagle',
    continent: "Brittanic",
    tier: 1
  },
  {
    shortName: "Dragons",
    logo: "diamond",
    colors: [
      { primary: "0, 100, 72" },
      { secondary: "11, 73, 36" },
      { away: "43, 100, 61" },
      { other: "157, 73, 60" }
    ],
    stadium: {
      name: "Father's Pride",
      capacity: 55000
    },
    name: 'Red Dragons',
    continent: "Brittanic",
    tier: 1
  },
  {
    shortName: "Tortugas",
    logo: "diamond",
    colors: [
      { primary: "0, 100, 72" },
      { secondary: "11, 73, 36" },
      { away: "43, 100, 61" },
      { other: "157, 73, 60" }
    ],
    stadium: {
      name: "Concha de Tortuga",
      capacity: 40052
    },
    name: 'Las Tortugas',
    continent: "Brittanic",
    tier: 1
  },
  {
    shortName: "de Lys",
    logo: "diamond",
    colors: [
      { primary: "0, 100, 72" },
      { secondary: "11, 73, 36" },
      { away: "43, 100, 61" },
      { other: "157, 73, 60" }
    ],
    stadium: {
      name: "Le Parc",
      capacity: 41600
    },
    name: 'Fleur de Lys',
    continent: "Brittanic",
    tier: 1
  },
  {
    shortName: "Union",
    logo: "concentric",
    colors: [
      { primary: "0, 100, 72" },
      { secondary: "11, 73, 36" },
      { away: "43, 100, 61" },
      { other: "157, 73, 60" }
    ],
    stadium: {
      name: "The Free Press",
      capacity: 26800
    },
    name: 'The Union',
    continent: "Brittanic",
    tier: 1
  },
  {
    shortName: "Saints",
    logo: "diamond",
    colors: [
      { primary: "0, 100, 72" },
      { secondary: "11, 73, 36" },
      { away: "43, 100, 61" },
      { other: "157, 73, 60" }
    ],
    stadium: {
      name: "Church Street",
      capacity: 32600
    },
    name: 'Merry Saints',
    continent: "Brittanic",
    tier: 1
  },
  {
    shortName: "Irons",
    logo: "diamond",
    colors: [
      { primary: "0, 100, 72" },
      { secondary: "11, 73, 36" },
      { away: "43, 100, 61" },
      { other: "157, 73, 60" }
    ],
    stadium: {
      name: "Castle Street",
      capacity: 57600
    },
    name: 'Ironmen',
    continent: "Brittanic",
    tier: 1
  },
  {
    shortName: "Shamrock",
    logo: "diamond",
    colors: [
      { primary: "0, 100, 72" },
      { secondary: "11, 73, 36" },
      { away: "43, 100, 61" },
      { other: "157, 73, 60" }
    ],
    stadium: {
      name: "True Meadow",
      capacity: 21400
    },
    name: 'White Shamrock',
    continent: "Brittanic",
    tier: 1
  },
  {
    shortName: "Lions",
    logo: "concentric",
    colors: [
      { primary: "0, 100, 72" },
      { secondary: "11, 73, 36" },
      { away: "43, 100, 61" },
      { other: "157, 73, 60" }
    ],
    stadium: {
      name: "Middle Kindgom",
      capacity: 33700
    },
    name: 'Aker Lions',
    continent: "Brittanic",
    tier: 1
  },
  {
    shortName: "Palms",
    logo: "shield",
    colors: [
      { primary: "0, 100, 72" },
      { secondary: "11, 73, 36" },
      { away: "43, 100, 61" },
      { other: "157, 73, 60" }
    ],
    stadium: {
      name: "Botanical Gardens",
      capacity: 32300
    },
    name: 'Palmettes',
    continent: "Brittanic",
    tier: 1
  },
  {
    shortName: "Lotus",
    logo: "diamond",
    colors: [
      { primary: "0, 100, 72" },
      { secondary: "11, 73, 36" },
      { away: "43, 100, 61" },
      { other: "157, 73, 60" }
    ],
    stadium: {
      name: "Westow Park",
      capacity: 25400
    },
    name: 'Sacred Lotus',
    continent: "Brittanic",
    tier: 1
  },
  {
    shortName: "Bears",
    logo: "diamond",
    colors: [
      { primary: "0, 100, 72" },
      { secondary: "11, 73, 36" },
      { away: "43, 100, 61" },
      { other: "157, 73, 60" }
    ],
    stadium: {
      name: "Boothferry Road",
      capacity: 25400
    },
    name: 'Atlas Bears',
    continent: "Brittanic",
    tier: 1
  },
  {
    shortName: "Hornets",
    logo: "diamond",
    colors: [
      { primary: "0, 100, 72" },
      { secondary: "11, 73, 36" },
      { away: "43, 100, 61" },
      { other: "157, 73, 60" }
    ],
    stadium: {
      name: "Golden Hart Road",
      capacity: 21400
    },
    name: 'Metropolitan Hornets',
    continent: "Brittanic",
    tier: 1
  },
  {
    shortName: "Motors",
    logo: "diamond",
    colors: [
      { primary: "0, 100, 72" },
      { secondary: "11, 73, 36" },
      { away: "43, 100, 61" },
      { other: "157, 73, 60" }
    ],
    stadium: {
      name: "The Sound",
      capacity: 27900
    },
    name: 'Motor Town',
    continent: "Brittanic",
    tier: 1
  },
  {
    shortName: "Empress",
    logo: "diamond",
    colors: [
      { primary: "0, 100, 72" },
      { secondary: "11, 73, 36" },
      { away: "43, 100, 61" },
      { other: "157, 73, 60" }
    ],
    stadium: {
      name: "The Parade",
      capacity: 21000
    },
    name: 'Old Empress',
    continent: "Brittanic",
    tier: 1
  },
  {
    shortName: "Park",
    logo: "diamond",
    colors: [
      { primary: "0, 100, 72" },
      { secondary: "11, 73, 36" },
      { away: "43, 100, 61" },
      { other: "157, 73, 60" }
    ],
    stadium: {
      name: "Northern Park",
      capacity: 11400
    },
    name: 'Northern Park',
    continent: "Brittanic",
    tier: 1
  },
  {
    shortName: "Racing",
    logo: "diamond",
    colors: [
      { primary: "0, 100, 72" },
      { secondary: "11, 73, 36" },
      { away: "43, 100, 61" },
      { other: "157, 73, 60" }
    ],
    stadium: {
      name: "Home Park",
      capacity: 10000
    },
    name: 'Fortuna Racing',
    continent: "Brittanic",
    tier: 2
  },
  {
    shortName: "Hombres",
    logo: "diamond",
    colors: [
      { primary: "0, 100, 72" },
      { secondary: "11, 73, 36" },
      { away: "43, 100, 61" },
      { other: "157, 73, 60" }
    ],
    stadium: {
      name: "Home Park",
      capacity: 10000
    },
    name: 'Hombres de Hielo',
    continent: "Brittanic",
    tier: 2
  },
  {
    shortName: "Fortezza",
    logo: "diamond",
    colors: [
      { primary: "0, 100, 72" },
      { secondary: "11, 73, 36" },
      { away: "43, 100, 61" },
      { other: "157, 73, 60" }
    ],
    stadium: {
      name: "Home Park",
      capacity: 10000
    },
    name: 'Fortezza di Lucertola',
    continent: "Brittanic",
    tier: 2
  },
  {
    shortName: "Chevaliers",
    logo: "diamond",
    colors: [
      { primary: "0, 100, 72" },
      { secondary: "11, 73, 36" },
      { away: "43, 100, 61" },
      { other: "157, 73, 60" }
    ],
    stadium: {
      name: "Home Park",
      capacity: 10000
    },
    name: 'Les Chevaliers',
    continent: "Brittanic",
    tier: 2
  },
  {
    shortName: "Jackdaws",
    logo: "diamond",
    colors: [
      { primary: "0, 100, 72" },
      { secondary: "11, 73, 36" },
      { away: "43, 100, 61" },
      { other: "157, 73, 60" }
    ],
    stadium: {
      name: "Home Park",
      capacity: 10000
    },
    name: 'The Jackdaws',
    continent: "Brittanic",
    tier: 2
  },
  {
    shortName: "Atomic",
    logo: "diamond",
    colors: [
      { primary: "0, 100, 72" },
      { secondary: "11, 73, 36" },
      { away: "43, 100, 61" },
      { other: "157, 73, 60" }
    ],
    stadium: {
      name: "Home Park",
      capacity: 10000
    },
    name: 'Atomic Kingfisher',
    continent: "Brittanic",
    tier: 2
  },
  {
    shortName: "Pearls",
    logo: "diamond",
    colors: [
      { primary: "0, 100, 72" },
      { secondary: "11, 73, 36" },
      { away: "43, 100, 61" },
      { other: "157, 73, 60" }
    ],
    stadium: {
      name: "Home Park",
      capacity: 10000
    },
    name: 'The Pearls',
    continent: "Brittanic",
    tier: 2
  },
  {
    shortName: "Aztecs",
    logo: "diamond",
    colors: [
      { primary: "0, 100, 72" },
      { secondary: "11, 73, 36" },
      { away: "43, 100, 61" },
      { other: "157, 73, 60" }
    ],
    stadium: {
      name: "Home Park",
      capacity: 10000
    },
    name: 'Forgotten Aztecs',
    continent: "Brittanic",
    tier: 2
  },
  {
    shortName: "Comets",
    logo: "diamond",
    colors: [
      { primary: "0, 100, 72" },
      { secondary: "11, 73, 36" },
      { away: "43, 100, 61" },
      { other: "157, 73, 60" }
    ],
    stadium: {
      name: "Home Park",
      capacity: 10000
    },
    name: 'Fallen Comets',
    continent: "Brittanic",
    tier: 2
  },
  {
    shortName: "Vipers",
    logo: "diamond",
    colors: [
      { primary: "0, 100, 72" },
      { secondary: "11, 73, 36" },
      { away: "43, 100, 61" },
      { other: "157, 73, 60" }
    ],
    stadium: {
      name: "Home Park",
      capacity: 10000
    },
    name: 'Queen Vipers',
    continent: "Brittanic",
    tier: 2
  },
  {
    shortName: "Riders",
    logo: "diamond",
    colors: [
      { primary: "0, 100, 72" },
      { secondary: "11, 73, 36" },
      { away: "43, 100, 61" },
      { other: "157, 73, 60" }
    ],
    stadium: {
      name: "Home Park",
      capacity: 10000
    },
    name: 'Dark Riders',
    continent: "Brittanic",
    tier: 2
  },
  {
    shortName: "Major",
    logo: "diamond",
    colors: [
      { primary: "0, 100, 72" },
      { secondary: "11, 73, 36" },
      { away: "43, 100, 61" },
      { other: "157, 73, 60" }
    ],
    stadium: {
      name: "Home Park",
      capacity: 10000
    },
    name: 'Ursa Major',
    continent: "Brittanic",
    tier: 2
  },
  {
    shortName: "Angels",
    logo: "diamond",
    colors: [
      { primary: "0, 100, 72" },
      { secondary: "11, 73, 36" },
      { away: "43, 100, 61" },
      { other: "157, 73, 60" }
    ],
    stadium: {
      name: "Home Park",
      capacity: 10000
    },
    name: 'Spartan Angels',
    continent: "Brittanic",
    tier: 2
  },
  {
    shortName: "Panthers",
    logo: "diamond",
    colors: [
      { primary: "0, 100, 72" },
      { secondary: "11, 73, 36" },
      { away: "43, 100, 61" },
      { other: "157, 73, 60" }
    ],
    stadium: {
      name: "Home Park",
      capacity: 10000
    },
    name: 'Endless Panthers',
    continent: "Brittanic",
    tier: 2
  },
  {
    shortName: "Magpies",
    logo: "diamond",
    colors: [
      { primary: "0, 100, 72" },
      { secondary: "11, 73, 36" },
      { away: "43, 100, 61" },
      { other: "157, 73, 60" }
    ],
    stadium: {
      name: "Home Park",
      capacity: 10000
    },
    name: 'Seven Magpies',
    continent: "Brittanic",
    tier: 2
  },
  {
    shortName: "Jets",
    logo: "diamond",
    colors: [
      { primary: "0, 100, 72" },
      { secondary: "11, 73, 36" },
      { away: "43, 100, 61" },
      { other: "157, 73, 60" }
    ],
    stadium: {
      name: "Home Park",
      capacity: 10000
    },
    name: 'Jumbo Jets',
    continent: "Brittanic",
    tier: 2
  },
  {
    shortName: "Roses",
    logo: "diamond",
    colors: [
      { primary: "0, 100, 72" },
      { secondary: "11, 73, 36" },
      { away: "43, 100, 61" },
      { other: "157, 73, 60" }
    ],
    stadium: {
      name: "Home Park",
      capacity: 10000
    },
    name: 'Lunar Roses',
    continent: "Brittanic",
    tier: 2
  },
  {
    shortName: "Bobolinks",
    logo: "diamond",
    colors: [
      { primary: "0, 100, 72" },
      { secondary: "11, 73, 36" },
      { away: "43, 100, 61" },
      { other: "157, 73, 60" }
    ],
    stadium: {
      name: "Home Park",
      capacity: 10000
    },
    name: 'Sporting Bobolink',
    continent: "Brittanic",
    tier: 2
  },
  {
    shortName: "Indigos",
    logo: "diamond",
    colors: [
      { primary: "0, 100, 72" },
      { secondary: "11, 73, 36" },
      { away: "43, 100, 61" },
      { other: "157, 73, 60" }
    ],
    stadium: {
      name: "Home Park",
      capacity: 10000
    },
    name: 'Indigo Midnight',
    continent: "Brittanic",
    tier: 2
  },
  {
    shortName: "Storm",
    logo: "diamond",
    colors: [
      { primary: "0, 100, 72" },
      { secondary: "11, 73, 36" },
      { away: "43, 100, 61" },
      { other: "157, 73, 60" }
    ],
    stadium: {
      name: "Home Park",
      capacity: 10000
    },
    name: 'November Storm',
    continent: "Brittanic",
    tier: 2
  },
]

teams.map((team, i) => {
  team.id = i
  team.stature = Math.round(team.stadium.capacity / 1000)
  team.formation = "4-4-2"
  team.tactic = tactic[randomNo(3)]
  team.unbeatenRun = 0
  team.squadOrder = new Array(20).fill().map((e, j) => { return (i + 1) * 20 + j })
  team.bankBalance = team.stadium.capacity * 19 // 19 home games per year
  team.ready = false
  return team
});

export default teams;
