import { getBidIndex, shouldMarketBeOpen } from "../functions/market"

let initialState = { bids: [], isOpen: true }

const marketReducer = (state = initialState, action) => {
  let market = { ...state }
  let bid
  const newData = action.market

  switch (action.type) {
    case 'newGame':
      return initialState
    case 'gameJoined':
      return action.market
    case 'incrementalServerData':
      return (newData !== undefined) ? newData : state
    case 'fullServerData':
      return (newData !== undefined) ? newData : {}
    case 'progressToDate':
      const shouldBeOpen = shouldMarketBeOpen(action.newDate)
      if (market.isOpen !== shouldBeOpen) {
        market.isOpen = shouldBeOpen
        return market
      }
      return state
    case 'releasePlayer': // action { player, teamId, fee }
      // Ensure any bids for this player are rejected
      // TODO: Maybe notify players team of this, or autosign for any team?
      market.bids = [...market.bids]
      market.bids.forEach((bid, i) => {
        if (bid.playerId === action.player.id && bid.status === "") {
          let newBid = { ...bid }
          newBid.status = "rejected"
          market.bids[i] = newBid
        }
      });

      return market

    case 'bidForPlayer':
      market.bids = [...market.bids]
      market.bids.unshift(action.bid)
      return market

    case 'loanBidForPlayer':
      market.bids = [...market.bids]
      market.bids.unshift({ type: "loan", playerId: action.playerId, buyingTeamId: action.buyingTeamId, sellingTeamId: action.sellingTeamId, dateTime: action.dateTime, status: "" })
      return market

    // Thinking aloud: In transfer window: AI teams know their weakest position (GK, DF, MF, FW) rating-wise
    // They'll bid for another team's player if:
    // [1] his position matches their need,
    // [2] his rating value is greater than the average of their weakest position (e.g. They have: GK: 44, GK: 66; 55 average. Transfer listed player's rating > 55)
    // [3] If they have enough money to afford the bid
    // If bids are not responded to in a single window they are rejected on the last turn

    case 'bidAccepted':
    case 'bidRejected':
      // Just update the status
      market.bids = [...market.bids]
      bid = { ...action.bid }
      bid.status = action.type === 'bidAccepted' ? "accepted" : "rejected"
      market.bids[getBidIndex(market.bids, bid)] = bid

      return market

    default:
      return state
  }
};

export default marketReducer;
