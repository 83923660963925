const cupYears = [
  [
    {
      year: 2021,
      teams: [
        {teamId:0},{teamId:1},{teamId:2},{teamId:3},
        {teamId:4},{teamId:5},{teamId:6},{teamId:7},
        {teamId:8},{teamId:9},{teamId:10},{teamId:11},
        {teamId:12},{teamId:13},{teamId:14},{teamId:15}
      ]
    }
  ],
  [
    {
      year: 2021,
      teams: [
        {teamId:0},{teamId:1},{teamId:2},{teamId:3},
        {teamId:4},{teamId:5},{teamId:6},{teamId:7},
        {teamId:20},{teamId:21},{teamId:22},{teamId:23},
        {teamId:24},{teamId:25},{teamId:26},{teamId:27}
      ]
    }
  ],
  [
    {
      year: 2021,
      teams: [
        {teamId:20},{teamId:21},{teamId:22},{teamId:23},
        {teamId:24},{teamId:25},{teamId:26},{teamId:27},
        {teamId:28},{teamId:29},{teamId:30},{teamId:31},
        {teamId:32},{teamId:33},{teamId:34},{teamId:35}
      ]
    }
  ]
]

cupYears.forEach(cups => cups[0].teams.forEach(team => team.knockedOut = false))

export default cupYears;
