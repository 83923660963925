const getModifierObject = (attackingModifier = 0, defendingModifier = 0) => {
  return {
    attackingModifier: attackingModifier,
    defendingModifier: defendingModifier
  }
}

const matchFlows = [
  [
    // All out attack / counter-attack?
    getModifierObject(0.1, -0.2),
    getModifierObject(-0.3, 0.2),
  ],
  [
    // Some other phase
    getModifierObject(),
    getModifierObject(),
  ]
];
export default matchFlows;
