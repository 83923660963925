import React from "react";
const TextInput = (props) => {
    const { placeholder, onChangeFunction, required, name } = props
    let className = 'rounded px-2 py-2 transition-all text-xs border border-slate-800'
    className = required
        ? className + ' border-red-600 bg-red-100'
        : className
    return <input
        type="text"
        id={name}
        placeholder={placeholder}
        className={className}
        onChange={onChangeFunction}
    />
}
export default TextInput