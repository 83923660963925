import teamsData from "../data/teams"
import { findPlayerPos } from "../functions/teams"

// Give all teams an ID
const initialState = teamsData

const teamsReducer = (state = initialState, action) => {
  let teams = [...state]
  let team, buyingTeam, sellingTeam, squadOrder
  const newData = action.teams

  switch (action.type) {
    case 'newGame':
      return initialState
    case 'gameJoined':
      return action.teams
    case 'incrementalServerData':
      return (newData !== undefined) ? newData : state
    case 'fullServerData':
      return (newData !== undefined) ? newData : []
    case 'clubSelected':
      teams[action.teamId] = { ...teams[action.teamId] }
      teams[action.teamId].userId = action.userId
      return teams


    case 'movePlayerPosition':
      let currentIndex = action.currentIndex
      let newIndex = action.newIndex
      team = { ...teams[action.teamId] }
      team.squadOrder = [...team.squadOrder]

      let temp = team.squadOrder[newIndex]
      team.squadOrder[newIndex] = team.squadOrder[currentIndex]
      team.squadOrder[currentIndex] = temp

      teams[action.teamId] = team
      return teams


    case 'bidAccepted':
      sellingTeam = { ...teams[action.bid.sellingTeamId] }
      buyingTeam = { ...teams[action.bid.buyingTeamId] }
      if (action.bid.type === "transfer") {
        buyingTeam.bankBalance -= action.bid.fee
        sellingTeam.bankBalance += action.bid.fee
      }
      transferPlayer(sellingTeam, buyingTeam, action.bid.playerId)
      teams[buyingTeam.id] = buyingTeam
      teams[sellingTeam.id] = sellingTeam

      return teams

    case 'endPlayerLoan':
      teams[action.borrowingTeamId] = { ...teams[action.borrowingTeamId] }
      teams[action.owningTeamId] = { ...teams[action.owningTeamId] }
      transferPlayer(teams[action.borrowingTeamId], teams[action.owningTeamId], action.playerId)
      return teams

    case 'adjustBankBalance':
      team = { ...teams[action.teamId] }
      team.bankBalance += action.change
      teams[action.teamId] = team
      return teams

    case 'changeFormation':
      team = { ...teams[action.teamId] }
      team.formation = action.formation
      teams[action.teamId] = team
      return teams

    case 'changeTactic':
      team = { ...teams[action.teamId] }
      team.tactic = action.tactic
      teams[action.teamId] = team
      return teams

    case 'releasePlayer':
      team = { ...teams[action.teamId] }
      squadOrder = [...team.squadOrder]
      const indexToRelease = squadOrder.findIndex(playerId => playerId === action.player.id)
      squadOrder.splice(indexToRelease, 1)
      team.squadOrder = squadOrder
      team.bankBalance -= action.fee
      teams[action.teamId] = team
      return teams

    case 'signFreeAgent':
      team = { ...teams[action.teamId] }
      squadOrder = [...team.squadOrder]
      squadOrder.push(action.player.id)
      team.squadOrder = squadOrder
      teams[action.teamId] = team
      return teams

    case 'matchPlayed':
      const result = action.result
      const { homeId, awayId } = result.fixture
      teams[homeId] = { ...teams[homeId] }
      teams[awayId] = { ...teams[awayId] }
      if (result.homeScore > result.awayScore) {
        teams[homeId].unbeatenRun++
        teams[awayId].unbeatenRun = 0
      } else if (result.awayScore > result.homeScore) {
        teams[homeId].unbeatenRun = 0
        teams[awayId].unbeatenRun++
      } else {
        teams[homeId].unbeatenRun++
        teams[awayId].unbeatenRun++
      }

      teams[homeId].bankBalance += result.attendance * 50
      teams[homeId].bankBalance -= result.homeSalary
      teams[awayId].bankBalance -= result.awaySalary
      return teams

    case 'relegateTeam':
      team = { ...teams[action.teamId] }
      team.tier++
      teams[action.teamId] = team
      return teams

    case 'promoteTeam':
      team = { ...teams[action.teamId] }
      team.tier--
      teams[action.teamId] = team
      return teams

    case 'promotePlayerToSeniorSquad':
      team = { ...teams[action.teamId] }
      squadOrder = [...team.squadOrder]
      squadOrder.push(action.playerId)
      teams[team.id].squadOrder = squadOrder
      return teams

    case 'demotePlayerToYouthSquad':
      team = { ...teams[action.teamId] }
      teams[team.id].squadOrder = team.squadOrder.filter(playerId => playerId !== action.playerId)
      return teams

    case 'teamReady':
      team = { ...teams[action.teamId] }
      team.ready = true
      teams[action.teamId] = team
      return teams

    case 'unreadyTeams':
      teams.forEach(t => {
        team = { ...teams[t.id] }
        team.ready = false
        teams[t.id] = team
      })
      return teams

    default:
      return state
  }
};

const transferPlayer = (fromTeam, toTeam, playerId) => {
  toTeam.squadOrder = [...toTeam.squadOrder]
  fromTeam.squadOrder = [...fromTeam.squadOrder]
  toTeam.squadOrder.push(fromTeam.squadOrder.splice(findPlayerPos(fromTeam, playerId), 1).pop())
}

export default teamsReducer;
